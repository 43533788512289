import axios from 'axios';

const actions = {
  deleteData: (context, payload) => {
    return axios.delete(`/api/${payload.route}/${payload.id}/deletar`)
      .then((response) => {
        return response.data;
      }).catch(error => {
        return error.response.data;
      });
  },
  disableData: (context, payload) => {
    return axios.put(`/api/${payload.route}/${payload.id}/status`)
      .then((response) => {
        return response.data;
      }).catch(error => {
        return error.response.data;
      });
  },
  showData: (context, payload) => {
    return axios.get(`/api/${payload.route}/${payload.id}/detalhes`)
      .then((response) => {
        return response.data;
      }).catch(error => {
        return error.response.data;
      });
  },
  recoveryData: (context, payload) => {
    return axios.put(`/api/${payload.route}/${payload.id}/recuperar`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  }
};
export default {
  actions
};
