import axios from 'axios';
import Cookies from 'js-cookie';
import router from './router';
import store from '../store';
import _ from 'lodash';

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error.response);
    switch (error.response.status) {
      case 401:
        Cookies.remove('access_token');
        Cookies.remove('refresh_token');
        Cookies.remove('expiration');
        localStorage.clear();
        router.push({
          name: 'login'
        });
        break;
      case 402:
        store.commit('DEL_VISITED_VIEWS', {
          name: router.history.current.name,
          path: router.history.current.path
        });
        if (router.history.current.name != 'comprar' && error.response.data.reason !== 'UPGRADE_PLAN') {
          router.push({
            name: 'comprar',
            params: {
              message: error.response.data.message
            }
          });
        } else if (error.response.data.reason === 'UPGRADE_PLAN') {
          router.push({
            name: 'upgrade',
            params: {
              message: error.response.data.message,
              modulo: error.response.data.modulo
            }
          });
        }
        break;
      case 422:
      case 429:
        try {
          let errors = _.values(error.response.data.errors);
          let msg = Array.isArray(errors[0]) ? _.first(errors)[0] : _.first(errors);
          toastr({
            icon: 'error',
            title: msg
          });
        } catch (e) {
          console.log(e);
          toastr({
            icon: 'warning',
            title: 'Houve um erro de validação.'
          });
        }
        break;
      case 403:
        store.commit('DEL_VISITED_VIEWS', {
          name: router.history.current.name,
          path: router.history.current.path
        });
        //  toastr.error('Você não tem permissão para o recurso que estava procurando. Necessário a permissão de <b>' + error.response.data.message + '</b> para continuar. Verifique com o gestor de sua empresa.');
        router.push({
          name: '403',
          params: {
            message: error.response.data.message
          }
        });
        break;
      case 451:
        store.commit('DEL_VISITED_VIEWS', {
          name: router.history.current.name,
          path: router.history.current.path
        });
        //  toastr.error('Você não tem permissão para o recurso que estava procurando. Necessário a permissão de <b>' + error.response.data.message + '</b> para continuar. Verifique com o gestor de sua empresa.');
        router.push({
          name: '451',
          params: {
            termos: error.response.data.termos
          }
        });
        break;
      case 404:
       /*  store.commit('DEL_VISITED_VIEWS', {
          name: router.history.current.name,
          path: router.history.current.path
        });
        //  toastr({ icon: 'error', title: 'Poxa.. não encontramos o endereço que você estava procurando.'});
        router.push({
          name: 'notFound'
        }); */
        break;
      case 500:
        toastr({
          icon: 'error',
          title: trans('message.error')
        });
        break;
      case 406:
        toastr({
          icon: error.response.data.status,
          title: error.response.data.message
        });
        break;
    }
    return Promise.reject(error);
  }
);