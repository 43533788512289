<template>
  <div class="position-relative">
    <div id="app">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {
        whatsapp: true,
      }
    },
     beforeMount() {
      let hide = localStorage.getItem("disableWhatsApp");
      if (hide) {
        this.whatsapp = false;
      }
     },
    methods: {
      setOffWhatsApp() {
        localStorage.setItem("disableWhatsApp", true);
        this.whatsapp = false;
      },
    },
  }
</script>

<style scoped>
  #whatsapp {
    right: 40px;
    /* top: 40px; */
    bottom: 40px;
    z-index: 999;
  }

  #whatsapp svg {
    width: 50px;
    -webkit-filter: drop-shadow(0px 0px 10px #555);
    filter: drop-shadow(0px 0px 10px #555);
  }

  @media screen and (max-width: 991px) {
    #whatsapp {
      bottom: 0%;
    }
  }

  @keyframes coming {
    from {
      transform: translateX(100vw);
    }

    to {
      transform: translateX(0);
    }
  }

  @keyframes going {
    from {
      transform: translateX(0);
      position: fixed;
    }

    to {
      transform: translateX(100vw);
      position: fixed;
    }
  }
</style>