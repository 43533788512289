import axios from 'axios';

const state = {
    data: [],
    select: [],
    orderables: [{
            title: 'Nome',
            name: 'name'
        },
        {
            title: 'Cadastrado em',
            name: 'created_at'
        }
    ],
    filterGroups: [{
        name: 'Cupom',
        filters: [{
                title: 'Nome',
                name: 'name',
                type: 'string'
            },
            {
                title: 'Cadastrado em',
                name: 'created_at',
                type: 'datetime'
            }
        ]
    }]
};

const mutations = {
    SET_CUPOM: (state, payload) => {
        state.data = payload;
    },
    SET_CUPOM_SELECT: (state, payload) => {
        state.select = payload;
    },
};

const actions = {
    setCupons: (context, payload) => {
        context.commit('SET_CUPOM', payload);
    },
    setCuponsDeleted: (context, payload) => {
        context.commit('SET_CUPOM_DELETED', payload);
    },
    storeCupom: (context, payload) => {
        return axios.post('/api/cupom/cadastrar', payload)
            .then(response => {
                return response.data;
            }).catch(error => {
                return error;
            });
    },
    updateCupom: (context, payload) => {
        return axios.put(`/api/cupom/${payload.id}/editar`, payload).then(response => {
            return response.data;
        }).catch(error => {
            return error;
        });
    },
    getCupomById: (context, payload) => {
        return axios.get(`/api/cupom/${payload}/detalhes`).then(response => {
            return response.data;
        }).catch(error => {
            return error;
        });
    }, 
};
export default {
    state,
    mutations,
    actions
};