import axios from 'axios';

const actions = {
    getDashboard: (context, payload) => {
        return axios.post('/api/dash/inicial', payload).then(response => {
            return response.data;
        }).catch(error => {
            return error.response.data;
        });
    },   
}
export default {
    actions
};