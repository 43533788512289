import axios from 'axios';
import socket from '../../events/socket-connect';

const state = {
  refresh_id: null,
  data: {
    id: null,
    email: '',
    empresa: {},
    permissoes: []
  },
  localSettings: {
    user_language: 'pt_BR',
    user_skin: 'skin-blue-light',
    box: 'box-primary',
    btn: 'btn-primary',
    tab: 'tab-primary',
    nav: 'navbar-primary',
    layout: {},
  },
  select: [],
  index: [],
  orderables: [{
      title: 'Nome',
      name: 'nome'
    },
    {
      title: 'Documento',
      name: 'documento'
    },
    {
      title: 'Cadastrado em',
      name: 'created_at'
    }
  ],
  filterGroups: [{
    name: 'Usuario',
    filters: [{
        title: 'Nome',
        name: 'nome',
        type: 'string'
      },
      {
        title: 'Documento',
        name: 'documento',
        type: 'document'
      },
      {
        title: 'Email',
        name: 'email',
        type: 'string'
      },
      {
        title: 'Cadastrado em',
        name: 'created_at',
        type: 'datetime'
      }
    ]
  }]
};

const mutations = {
  UPDATE_USER_LIST: (state, payload) => {
    let test = state.index.findIndex(x => x.id == payload);
    state.index[test].status = !state.index[test].status;
  },
  SET_USER: (state, payload) => {
    state.data = payload;
    if (!state.data.empresa) {
      state.data.empresa = {}
      state.data.permissions = []
    }
  },
  SET_USER_PERMISSION: (state, payload) => {
    state.data.permissions = payload;
  },
  SET_EMPRESA_NIVEIS: (state, payload) => {
    state.data.empresa.empresa_niveis = payload;
  },
  SET_USER_FOLDERS: (state, payload) => {
    state.data.folders = payload;
  },
  SET_LAYOUT: (state, payload) => {
    state.localSettings.layout.box = `box-${payload}`;
    state.localSettings.layout.btn = `btn-${payload}`;
    state.localSettings.layout.tab = `card-${payload}`;
    state.localSettings.layout.card = `card-${payload}`;
    state.localSettings.layout.nav = `navbar-${payload}`;
    state.localSettings.layout.check = `${payload}`;
    state.localSettings.layout.theme = `${payload}`;
  },
  CHANGE_LANGUAGE: (state, payload) => {
    state.localSettings.user_language = payload;
  },
  CHANGE_USER_SKIN: (state, payload) => {
    state.localSettings.user_skin = payload;
  },
  SET_USER_SELECT: (state, payload) => {
    state.select = payload;
  },
  SET_USERS: (state, payload) => {
    state.index = payload;
  },
};
const actions = {
  getUserData: (context) => {
    return axios.get('/api/auth/user')
      .then((response) => {
        if (response.data.status !== 401) {
          context.commit('SET_USER', response.data);
        }
        return response;
      }).then(response => {
        if (response.data.status !== 401) {
          socket.socketConnect();
        }
      })
      .catch(error => {
        console.log('erro ao pegar dados do usuário', error);
        return error;
      });
  },
  getUserPermission: (context) => {
    axios.get('/api/auth/permission')
      .then((response) => {
        context.commit('SET_USER_PERMISSION', response.data);
      }).catch(error => {
        console.log(error);
      });
  },
  getUserSelect: (context) => {
    axios.get('/api/empresa/usuarios')
      .then((response) => {
        context.commit('SET_USER_SELECT', response.data);
      }).catch(error => {
        console.log(error);
      });
  },
  checkUniqueEmail: (context, payload) => {
    return axios.get(`/api/username/${payload}`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  previewNotification: (context, payload) => {
    return axios.put(`/api/usuario/notification/${payload}/show`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  previewAllNotification: () => {
    return axios.put('/api/usuario/notifications/show').then(response => {
      return response.data;
    }).catch(error => {
      return error.response;
    });
  },
  deleteNotificationById: (context, payload) => {
    return axios.delete(`/api/usuario/notification/${payload}`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  deleteAllNotifications: () => {
    return axios.delete('/api/usuario/notifications').then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  changeLanguage: (context, payload) => {
    return axios.put(`/api/usuario/change-language/${payload}`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  changeUserSkin: (context, payload) => {
    axios.put(`/api/usuario/change-skin/${payload}`).then(() => {}).catch(error => {
      console.log(error.response.data);
    });
  },
  changeProfilePhoto: (context, payload) => {
    return axios.post('/api/usuario/photo', payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  storeUserEmail: (context, payload) => {
    return axios.post('/api/usuario/email', payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  changePassword: (context, payload) => {
    return axios.put('/api/usuario/password/', payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  updateUser: (context, payload) => {
    return axios.put('/api/user', payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  storeUser: (context, payload) => {
    return axios.post('/api/usuario/cadastrar', payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        return error.response.data;
      });
  },
  updateUserById: (context, payload) => {
    return axios.put(`/api/usuario/${payload.id}/editar`, payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  getUserById: (context, payload) => {
    return axios.get(`/api/usuario/${payload}/detalhes`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  getUserEdit: (context, payload) => {
    return axios.get(`/api/usuario/${payload}/editar`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  searchUser: (context, payload) => {
    return axios.get(`/api/users/search/${payload}`)
      .then((response) => {
        return response.data;
      }).catch(error => {
        console.log(error);
      });
  },
  setUsers: (context, payload) => {
    context.commit('SET_USERS', payload);
  },
  disableUser: (context, payload) => {
    return axios.put(`/api/usuario/${payload}/disable`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  getEula: (context, payload) => {
    return axios.get('/api/eula').then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  generateGoogleSecret: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get('/api/auth/otp')
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data);
        });
    });
    return promise;
  },
  changeTwoFactory: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.put('/api/auth/change-two-factory', payload)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data);
        });
    });
    return promise;
  },
  saveUserBasicInfo: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.put('/api/user-settings', payload)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data);
        });
    });
    return promise;
  },
  saveSystemSettings: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.put('/api/system-settings', payload)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data);
        });
    });
    return promise;
  },
  getSystemSettings: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get('/api/system-settings')
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data);
        });
    });
    return promise;
  },
  storeDevice: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post('/api/whatsapp-device', payload)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data);
        });
    });
    return promise;
  },
  getDeviceStatus: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get(`/api/whatsapp-status`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  disconectDevice: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.delete(`/api/whatsapp-device`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  getDeviceQrcode: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get(`/api/whatsapp-scan`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
};

export default {
  state,
  mutations,
  actions
};