export default {
  route: {
    404: 'Page not found',
    profile: 'Profile',
    notifications: 'Notifications',
    company: 'Company',
    companies: 'Companies',
    companyCreate: 'Register Company',
    companyEdit: 'Edit Company',

    dashboard: 'Home',
    holidays: 'Holidays',
    holidayCreate: 'Register Holiday',
    holidayEdit: 'Edit Holiday',
    holidayDeleted: 'Holidays Excluded',

    accounts: 'Accounts',
    accountCreate: 'Register Account',
    accountEdit: 'Edit Account',
    accountDeleted: 'Excluded Accounts',

    cities: 'Municipalities',
    municipalityCreate: 'Register Municipality',
    municipalityEdit: 'Edit Municipality',
    MunicipalityDeleted: 'Excluded Municipalities',

    posts: 'Posts',
    postCreate: 'Register Post',
    postEdit: 'Edit Post',
    postsDeleted: 'Excluded Posts',

    planAccounts: 'Account Plans',
    planAccountCreate: 'Register Account Plan',
    planAccountEdit: 'Edit Account Plan',
    planAccountDeleted: 'Excluded Account Plans',

    condPayment: 'Payment Terms',
    condPaymentCreate: 'Register Payment Terms',
    condPaymentEdit: 'Edit Payment Conditions',
    condPaymentDeleted: 'Excluded Payment Conditions',

    persons: 'Persons',
    personCreate: 'Register Person',
    personEdit: 'Edit Person',
    personDeleted: 'Excluded Persons',

    users: 'Users',
    userCreate: 'Register User',
    userEdit: 'Edit User',
    userDeleted: 'Excluded Users',
    userCompanies: 'User Companies',
    userPermissions: 'User Permissions',
    inputs: 'Accounts Receivable',
    outputs: 'Accounts Payable',
    caixa: 'Cash Flow',
    inputEdit: 'Edit Entry',
    inputCreate: 'Register Entry',
    outputEdit: 'Edit Output',
    outputCreate: 'Register Output',
    dre: 'DRE Report',
    financialReport: 'Financial Statement',
    settings: 'Settings'

  },
  'details': {
    'nire': 'NIRE é a sigla de Número de Identificação do Registro de Empresas. O NIRE é o registro de legalidade da empresa na Junta Comercial do Estado. É um número único que comprova que a empresa existe oficialmente.',
    'cnae': 'A CNAE ou, por extenso, Classificação Nacional de Atividades Econômicas, é uma forma de padronizar, em todo o território nacional, os códigos de atividades econômicas e os critérios de enquadramento usados pelos mais diversos órgãos da administração tributária do Brasil.',
    'simple_national': 'O Simples Nacional é um regime tributário diferenciado, simplificado e favorecido previsto na Lei Complementar nº 123, de 14.12.2006.',

  },

  validation: {
    max: (field, value) => `Oops, ${field} does not have to be greater than ${value}. Please, can you check this out?`,
    max_value: (field, value) => `Oops, ${field} does not have to be greater than ${value}. Please, can you check this out?`,
    min: (field, value) => `Oops, ${field} must be greater or equals than ${value}. Please, can you check this out?`,
    min_value: (field, value) => `Ops, ${field} must be greater or equals than ${value}. Please, can you check this out?`,
    accepted: (field) => `${field} needs to be accepted.`,
    recaptcha: (field) => `${field} is not correct.`,
    active_url: (field) => `${field} unfortunately is not a valid URL.`,
    after: (field) => `${field} must be a date after: date. Please, can you check this out?`,
    after_or_equal: (field) => `<b> ${field} </b> must be a date later than or equal to <b>:date </b>. Please, can you check this out?`,
    alpha: (field) => `${field} must contain only letters. Please, can you check this out?`,
    alpha_dash: (field) => `${field} must contain letters, numbers, and dashes. Please, can you check this out?`,
    alpha_num: (field) => `${field} must contain only letters and numbers. Please, can you check this out?`,
    array: (field) => `${field} must be an array. Please, can you check this out?`,
    before: (field) => `${field} must be a date before: date. Please, can you check this out?`,
    before_or_equal: (field) => `${field} must be a date before or equal to: date. Please, can you check this out?`,
    boolean: (field) => `${field} must be true or false. Please, can you check this out?`,
    confirmed: (field) => `Confirmation of ${field} does not match. Please, can you check this out?`,
    date: (field) => `${field} Unfortunately this is not a valid date. Please, can you check this out?`,
    date_format: (field) => `${field} unfortunately does not match the format: format. Please, can you check this out?`,
    different: (field) => `${field} e: other must be different. Please, can you check this out?`,
    digits: (field, value) => `${field} must have ${value} digits. Please, can you check this out?`,
    digits_between: (field) => `${field} must be between: min and: max digits. Please, can you check this out?`,
    dimensions: (field) => `${field} has invalid image dimensions. Please, can you check this out?`,
    distinct: (field) => `${field} has a duplicate value. Please, can you check this out?`,
    email: (field) => `${field} must be a valid email address. Please, can you check this out?`,
    exists: (field) => `${field} selected is invalid. Please, can you check this out?`,
    file: (field) => `${field} must be a file. Please, can you check this out?`,
    filled: (field) => `${field} is a required field. Please, can you check this out?`,
    image: (field) => `${field} must be an image. Please, can you check this out?`,
    in: (field) => `${field} is invalid. Please, can you check this out?`,
    in_array: (field) => `${field} does not exist in: other. Please, can you check this out?`,
    integer: (field) => `${field} must be an integer. Please, can you check this out?`,
    ip: (field) => `${field} must be a valid IP address. Please, can you check this out?`,
    json: (field) => `${field} must be a valid JSON. Please, can you check this out?`,
    mimes: (field) => `${field} must be a file of type:: values. Please, can you check this out?`,
    mimetypes: (field) => `${field} must be a file of type:: values. Please, can you check this out?`,
    not_in: (field) => `The ${field} entered is invalid. Please, can you check this out?`,
    numeric: (field) => `${field} must be a number. Please, can you check this out?`,
    present: (field) => `The ${field} field must be present. Please, can you check this out?`,
    regex: (field) => `${field} format is invalid. Please, can you check this out?`,
    required: (field) => `The ${field} field needs to be informed. Please, can you check this out?`,
    required_if: (field) => `The ${field} field needs to be informed when: other is: value. Please, can you check this out?`,
    required_unless: (field) => `${field} is required unless: other is in: values. Please, can you check this out?`,
    required_with: (field) => `The ${field} field needs to be informed when: values is present. Please, can you check this out?`,
    required_with_all: (field) => `The ${field} field must be informed when: values are present. Please, can you check this out?`,
    required_without: (field) => `The ${field} field must be informed when: values is not present. Please, can you check this out?`,
    required_without_all: (field) => `The ${field} field needs to be informed when none of these are present:: values. Please, can you check this out?`,
    same: (field) => `${field} and: other must be equal.`,
    string: (field) => `Oops, ${field} must be a string.`,
    timezone: (field) => `Oops, ${field} must be a valid timezone.`,
    unique: (field) => `Oops, ${field} is already in use.`,
    uploaded: (field) => `Oops, ${field} failed to be sent.`,
    url: (field) => `${field} format is invalid.`,
    array_not_found: () => 'Oops, the <b> CNPJ of the Matrix </b> was not found in the database, can you check this?',
    matrix_cnpj_error: () => 'Oops, the CNPJ of the Matrix </b> can not be the same as the one of the company you are registering, can you check it?',
    cnpj_invalid: () => 'Oops, the entered CNPJ is invalid. Please, can you check this? ',
    cpf_invalid: () => 'Oops, the entered CPF is invalid. Please, can you check this? ',
    cep_invalid: () => 'Oops, the reported CEP is invalid. Please, can you check this? ',
    wrong_date: () => 'Oops, the entered date is invalid. Please, can you check this? ',
    wrong_plot: () => 'Oops, the Parcel can not be greater than 60. Please can you check this?',
    req: '<title title = "This field is required"></title><label style = "color: red">*</label>',
    field_required: 'Fields marked with <label style ="color:red">*</label> are required.',
    field_valid_success: () => 'We did not find any errors with the: field informed.',
    please_check: 'Please check.',
    field_invalid: () => ': field Invalid.',
    field_valid: () => ': field Valid.',
    email_valid: () => 'E-mail available.',
    email_invalid: () => 'E-mail unavailable.',
    check_field: () => 'Check the: field.',
    check_field_2: () => 'Check: field.',
    require: () => 'Please fill in this field.',
    require_select: 'Please select an item from this list.',
    require_day: 'Please fill in this field. No. between 1-31 ',
    require_month: 'Please fill in this field. No. between 1-12 ',
    require_email: () => 'Please enter a valid Email.',
    composite_unique: (field) => `My fancy custom validation message for ${field}`,
    file_extension: () => 'Invalid file. Please check the <b>:name </b> extension. <B> Exception </b>: <b> Exception </ b>',
    document: 'Invalid document!',
  },
  'attributes': {
    name: 'Name',
    description: 'Description',
    code: 'Code',
    type: 'Type',
    date: 'Data',
    user_email: 'Username',
    pem_email: 'E-mail',
    user_contato: 'Name',
    note: 'Note',
    email_type: 'E-mail Type',
    account: 'Account',
    plan_account: 'Account Plan',
    neighborhood_user: 'Neighborhood',
    state_user: 'State',
    number_user: 'Number',
    plots: 'Plots',
    discont: 'Discount',
    penalty: 'Fine',
    interest: 'Interest',
    lack: 'Lack',
    full_name: 'Full Name',
    document: 'Document',
    ctps: 'Workbook',
    site: 'Site',
    social_network: 'Social Network',
    channel: 'Channel',
    commission: 'Comission',
    user: 'User | Users',
    emp_matrix: 'CNPJ Matrix',
    phone: 'Phone',
    cellphone: 'Cellphone',
    zip: 'ZIP',
    country: 'Country',
    state: 'State',
    neighborhood: 'Neighborhood',
    address: 'Address',
    number: 'Number',
    user_name: 'Full Name',
    user_phone: 'Phone',
    user_cellphone: 'Mobile',
    user_document: 'Document',
    crt: 'CRT',
    nire: 'Nire',
    nire_date: 'Data Nire',
    state_inscription: 'State Registration',
    city_inscription: 'Municipal Registration',
    cnae: 'CNAE',
    complement: 'complement',
    file: 'File',
    spouse: 'Spouse',
    contact: 'Contact',
    born_date: 'Date of Birth',
    city: 'City | Cities',
    Zip: 'CEP',
    branch: 'Extension',
    post: 'Post',
    posts: 'Posts',
    retype_password: 'Confirm password',
    'Contact Name': 'User Name',
    'EmailContact': 'E-Mail',
    'Email Company': 'E-Mail',
    'email': 'E-Mail',
    'TelephoneContact': 'Telephone',
    'CellularContact': 'Cellular',
    'password': 'Password',
    'Password': 'Password',
    'NovaSenha': 'New Password',
    'ConfirmSenha': 'Confirm new password',
    'CnpjMatrix': 'CNPJ Matrix',
    'cnpj': 'CNPJ',
    'CPF': 'CPF',
    'DataNire': 'Data Nire',
    'InscricaoMunicipal': 'Municipal Registration',
    'StateInscription': 'State Inscription',
    'Nire': 'Nire',
    'Code Bank': 'Code',
    'Bank Name': 'Name',
    'Account Name': 'Account Name',
    'Bank': 'Bank',
    'Agency': 'Agency',
    'Digito': 'Digito',
    'Number': 'Number',
    'Balance': 'Balance',
    'Value': 'Value',
    'Nome': 'Name',
    'Dia': 'Day',
    'Mes': 'Month',
    'Ano': 'Year',
    'Account': 'Bank Account',
    'Account to Compensate': 'Account to be Compensated',
    'social_name': 'Social Name',
    'PasswordCompany': 'Password',
    'Type': 'Type',
    'Wallet Type': 'Wallet Type',
    'TypeInscription': 'State Registration Type',
    'Class': 'Class',
    'Quantity': 'Quantity',
    'Period': 'Periocity',
    'DataPayment': 'Payment Date',
    'FinVencimento': 'Account expiration',
    'FinEmissao': 'Issue of the account',
    'Maturity': 'Maturity',
    'CnaePrincipal': 'CNAE Principal',
    'StartDate': 'StartDate',
    'EndDate': 'End Date',
    'Description': 'Description',
    'uni_sigla': 'Sigla',
    'uni_descricao': 'Description',
    'fk_uni_id': 'Drive',
    'fk_grup_id': 'Product Group',
    'prd_type': 'Type',
    'Payment Type': 'Payment Method',
    'fantasy_name': 'Fantasy Name',
    'RG': 'RG',
    'Contribution Type': 'Taxpayer Type',
    'operation_type': 'Operation Type',
    'ces_codigo': 'Code',
    'ces_ncm': 'NCM',
    'ces_categoria': 'Category',
    'ces_item': 'Item',
    'ces_descricao': 'Description',
    'cfo_code': 'Code',
    'g-recaptcha-response': 'I\'m not a robot',
    'fdt_descricao': 'Name',
    'holiday.fer_dia': 'Day',
    'holiday.fer_mes': 'Month',
    'holiday.fer_ano': 'Year',
    language: 'Language',
  },
  'words': {
    successful: 'Successful',
    register: 'Register',
    record: 'record',
    address: 'Address',
    contact: 'Contact',
    register_a_new_membership: 'Register a new member',
    i_forgot_my_password: 'I forgot my password',
    change_password: 'Change password',
    change_photo: 'Change photo',
    change_photo_user: 'Change profile photo',
    remember_me: 'Remember me',
    i_already_have_a_membership: 'Already a member',
    sign_in: 'Enter',
    user_settings: 'Profile',
    log_out: 'Exit',
    other_data: 'Other data',
    toggle_navigation: 'Change navigation',
    login_message: 'Sign in to start a new session',
    register_message: 'Register a new member',
    password_reset_message: 'Recover password',
    reset_password: 'Reset password',
    send_password_reset_link: 'Send password recovery link',
    atention: 'Attention!',
    active: 'Active',
    access: 'Access',
    delete: 'Delete',
    restore: 'Recover',
    create: 'New',
    create_new: 'Register New',
    cancel: 'Cancel',
    save: 'Save',
    edit: 'Edit',
    create_company: 'Register New Company',
    create_bank: 'Register new Bank',
    delete_company: 'Delete Company',
    delete_register: 'Delete record',
    delete_person: 'Delete Person',
    delete_bank: 'Delete Bank',
    delete_bank_account: 'Delete Bank Account',
    delete_plan_account: 'Delete Account Plan',
    plan_account: 'Account Plan',
    delete_condition_payment: 'Delete Payment Condition',
    delete_holiday: 'Delete Holiday',
    hello: 'Hello',
    result: 'Result',
    member: 'Member since',
    account_settings: 'Account Settings',
    account_plan: 'Account Plans',
    post_data: 'Cargo Data',
    account_data: 'Account Data',
    ncm_data: 'NCM data',
    cfop_data: 'CFOP Data',
    warehouse_data: 'Warehouse Data',
    unity_data: 'Unit Data',
    movement_type_data: 'Movement Type Data',
    group_data: 'Product Group Data',
    import_data: 'Import data',
    import: 'Import',
    export_data: 'Export data',
    export: 'Export',
    account_data_bank: 'Bank Account Data',
    edit_data: 'Edit Data',
    edit_account: 'Edit account',
    delete_account: 'Delete account',
    compensate: 'Compensate',
    profile: 'Profile',
    fields: 'Fields',
    cashier: 'cashier',
    personal_data: 'Personal Information',
    data_supervisor: 'Fiscal data',
    data_plan_account: 'Plan Information',
    data_contact: 'Contact Data - User',
    data_company: 'Company Data',
    company_address: 'Company Address',
    personal_data_company: 'Information of the Legal Entity',
    type_old_password: 'Enter your old password',
    old_password: 'Old password:',
    new_password: 'New Password:',
    confirm_new_password: 'Confirm New Password:',
    type_new_password: 'New password (min 6 digits)',
    confirm: 'Confirm',
    type_confirm_new_password: 'Confirm your new password',
    details: 'Details',
    show: 'Show',
    hide: 'Hide',
    weak: 'weak',
    Medium: 'Medium',
    strong: 'strong',
    random_password: 'Generate Password',
  },
  'auth': {
    'failed': 'Sorry, but the login information could not be found.',
    'user_disabled': 'Unfortunately the informed account is disabled, check with the administrators of your Company.',
    'company_disabled': 'The Company of the informed account is disabled, for more information contact by phone: (12) 3152-9360 or E-mail: soporte@mbd.com.br',
    'company_selected_disabled': 'The selected company is disabled, for more information contact by phone: (12) 3152-9360 or E-mail: soporte@mbd.com.br',
    'throttle': 'Damn ... You tried to log in often. Please try again in seconds seconds. ',
    'not_enter': 'Administration requires all users to log in.',
    'choice_company': 'Administration requires all users to select a Company before entering.',
    'password_wrong': 'Old password does not match. Please, can you check this out? ',
    'password_update_success': 'Password changed successfully.',
  },
  message: {
    remember: 'Keep Connected',
    session_closed: 'Session closed.',
    page_not_found: 'Page not found ..',
    page_not_found_title: 'Well.. we did not find the address you were looking for.',
    page_not_found_tip: 'Verify that the entered URL is correct. Click the button below to return to the homepage or send an error report. ',
    logout_success: 'Your session was successfully ended.',

  }
};