/* eslint-disable no-undef */
import company from './socket-response';
import store from '../store';
import Vue from 'vue';

export default {
  // Conectar nos canais
  async socketConnect() {
    let isAuthenticated = await Vue.prototype.$auth.check();
    if (!isAuthenticated) {
      return null;
    }
  },
  // Desconectar de todos os canais
  socketDisconnect() {
    //Echo.leave(`app.system-notifications${store.state.user.id}`);
   // Echo.leave(`app.company-notifications${store.state.user.empresa_id}`);
  }
};