import {
  _
} from 'core-js';

let places = require('../lang/cities');
const common = {
  data() {
    return {
      appName: 'Fominha APP',
      appShortName: 'FominhaApp'
    }
  },
  computed: {
    auth_user() {
      return this.$store.state.user.data;
    },
    empresaLogada() {
      return this.$store.state.user.data.empresa;
    },
    appLayout() {
      return this.$store.state.user.localSettings.layout;
    },
    estados() {
      return places.states;
    },
    allCities() {
      return places.cities;
    },
    paises() {
      return []
    },
  },
  methods: {
    formatCpf(val) {
      return val.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    formatCnpj(val) {
      return val.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
    },
    formatDocument(val) {
      return (val.length == 11) ? this.formatCpf(val) : this.formatCnpj(val)
    },
    formatTelefone(val) {
      return val.replace(/^(\d{2})(\d{4})?(\d{4})?/, "($1) $2-$3");
    },
    capitalize(request) {
      return request.charAt(0).toUpperCase() + request.slice(1);
    },
    ellipseString(str, n) {
      return str.substr(0, n - 1) + (str.length > n ? '...' : '');
    },
    getGtin(payload) {
      return !['null', null].includes(payload) ? payload : 'SEM GTIN';
    },
    copy(payload) {
      let el = document.createElement('input');
      el.value = payload;
      el.setAttribute('readonly', '');
      el.style = {
        position: 'absolute',
        left: '-9999px'
      };
      document.body.appendChild(el);
      // Select text inside element
      el.select();
      // Copy text to clipboard
      document.execCommand('copy');
      el.remove();
      toastr({
        title: 'Copiado para área de transferência',
        icon: 'info'
      });
    },
    getDefaultData(payload, defaultData) {
      return typeof payload === 'undefined' ? defaultData : payload;
    },
    formatMoney(input) {
      if (Number.isNaN(input)) {
        input = 0;
      }
      input = parseFloat(input);
      return input.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      });
    },
    // Formatar data de input para dd/MM/yyyy
    formatDate(value) {
      var date = moment(value, 'YYYY-MM-DD', true);
      let valid = ((new Date(value)).toString() !== 'Invalid Date' && value !== null);
      if (valid) {
        if (date.isValid()) {
          return moment(value).format('DD/MM/YYYY');
        } else {
          return moment(value).format(`DD/MM/YYYY [${trans('words.at')}] HH:mm`);
        }
      }
    },
    formatDateExtenso(value) {
      const month = new Date(value).toLocaleString('default', {
        month: 'long',
        year: 'numeric',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
      return month;
      /*  return `${date.getDate()} de ${month} de ${date.getFullYear()}
       ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`; */
    },
    formatPorcentage(payload) {
      return payload.toFixed(2).replace('.', ',') + ' %';
    },
    // a and b are javascript Date objects
    dateDiffInDays(a, b) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      // Discard the time and time-zone information.
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    },
    // String para data..
    formatarData(input) {
      input = input.toLocaleString().split(' ').slice(0, 2)[0];
      input = input.replace('/', '-').replace('/', '-');
      input = input.split(/\D/);
      input = input.reverse().join('-');
      return input;
    },
    divideMoney(precoTotal, numeroParcelas) {
      let valorParcela = parseFloat(precoTotal / numeroParcelas).toFixed(2);
      let valorUltimaParcela = precoTotal - valorParcela * (numeroParcelas - 1);
      valorUltimaParcela = valorUltimaParcela.toFixed(2);

      return {
        UltimaParcela: valorUltimaParcela,
        Parcela: valorParcela
      };
    },
    subtrairValor(a, b) {
      return (a - b).toFixed(2);
    },
    getPercentage(a, b) {
      let percentage = ((a - b) / a) * 100;
      return Math.abs(percentage.toFixed(2));
    },
    calcPercentage(num, percentage) {
      return ((parseFloat(percentage) / 100) * parseFloat(num));
    },
    removeItensFromArray(original, remove) {
      return original.filter(value => !remove.includes(value));
    },
    clearNumberStringsMoney(input) {
      if (typeof input === 'string') {
        return Number(
          input
          // Entre o [] os caracteres são aceitos, diferente disso será removido.
          .replace(/[^0-9,]+/g, '')
          .replace(',', '.'));
      } else {
        return input;
      }
    },
    isValidCnpj(cnpj) {
      cnpj = cnpj.replace(/[^\d]+/g, '');
      if (cnpj === '') return false;
      if (cnpj.length !== 14) {
        return false;
      }
      // Elimina CNPJs invalidos conhecidos
      if (cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999') {
        return false;
      }

      // Valida DVs
      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado !== Number(digitos.charAt(0))) {
        return false;
      }

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado !== Number(digitos.charAt(1))) {
        return false;
      }
      return true;
    },
    isValidCpf(strCPF) {
      strCPF = strCPF.replace(/[^\d]+/g, '');
      let Soma;
      let Resto;
      Soma = 0;

      switch (strCPF) {
        case '00000000000':
        case '11111111111':
        case '22222222222':
        case '33333333333':
        case '44444444444':
        case '55555555555':
        case '66666666666':
        case '77777777777':
        case '88888888888':
        case '99999999999':
          return false;
        default:
          break;
      }

      for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto === 10) || (Resto === 11)) Resto = 0;
      if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

      Soma = 0;
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto === 10) || (Resto === 11)) Resto = 0;
      if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
      return true;
    },
    isValidNfe(chaveAcesso) {
      const PESO = [4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2, 0];
      let aChave = '';
      let Soma = 0;
      let Verif = 0;
      let uf = ['12', '27', '13', '16', '29', '23', '53', '32', '52', '21', '31', '50', '51', '15', '25', '26', '22', '41', '33', '24', '11', '14', '43', '42', '28', '35', '17', '99'];

      //Verificando UF..   
      if (uf.indexOf(chaveAcesso.substring(0, 2)) === -1) {
        return false;
      }
      // verificando data (mes)
      if (parseInt(chaveAcesso.substring(6, 4)) > 12) {
        return false;
      }
      // verificando CNPJ
      if (!this.isValidCnpj(chaveAcesso.substring(20, 6))) {
        return false;
      }
      // verificando Tipo De Nota
      let modelos = ['55', '65', '59'];
      if (modelos.indexOf(chaveAcesso.substring(22, 20)) === -1) {
        return false;
      }
      // verificando tipo de emissao.
      /*  if (['1', '0', '6', '8'].indexOf(chaveAcesso.substring(35, 34)) === -1) {
        alert(chaveAcesso.substring(35, 34));
        return false;
      } */
      try {
        if (chaveAcesso.length < 44) {
          return 'valor menor que 44';
        }
        // StrPCopy(aChave,StringReplace(chaveAcesso,' ', '',[rfReplaceAll]));
        aChave = chaveAcesso;
        Soma = 0;
        for (let i = 0; i < aChave.length; i++) {
          Soma = Soma + (parseInt(aChave.charAt(i)) * PESO[i]);
        }
        if (Soma == 0) {
          alert(Soma)
          return false;
        }
        Soma = Soma - (11 * parseInt(Soma / 11));
        if ((Soma == 0) || (Soma == 1)) {
          Verif = 0;
        } else {
          Verif = (11 - Soma);
        }
        return (Verif == aChave.charAt(43));
      } catch (e) {
        return e;
      }
    },
    questionDelete(alert) {
      let span = document.createElement('span');
      span.innerHTML = alert.message;
      return {
        title: alert.title,
        icon: 'question',
        html: span,
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: trans('words.close'),
        confirmButtonText: trans('words.confirm'),
        confirmButtonClass: 'btn btn-danger btn-lg float-right',
        cancelButtonClass: 'btn btn-default btn-lg float-left',
        buttonsStyling: false,
        reverseButtons: true,
        showLoaderOnConfirm: true,
      };
    },
    questionDeletePermanent(name) {
      let span = document.createElement('span');
      span.innerHTML =
        `${trans('message.confirm_delete_alert')}<br><br>
      <small>${trans('message.confirm_delete')}<br>
      <strong style='word-wrap: break-word;'>${name}</strong> ? </small><br><br>`;
      return {
        title: trans('words.confirm'),
        icon: 'warning',
        html: span,
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: trans('words.close'),
        confirmButtonText: trans('words.confirm'),
        confirmButtonClass: 'btn btn-danger btn-lg float-right',
        cancelButtonClass: 'btn btn-default btn-lg float-left',
        buttonsStyling: false,
        reverseButtons: true,
        showLoaderOnConfirm: true,
      };

    },
    alertDone(message) {
      toastr({
        title: trans('words.done'),
        html: message || 'Sucesso!',
        icon: 'success',
        showCancelButton: true,
        allowOutsideClick: true,
        cancelButtonText: trans('words.close'),
        confirmButtonText: trans('words.close'),
        confirmButtonClass: 'btn btn-default btn-lg float-right',
        cancelButtonClass: 'btn btn-default btn-lg float-left invisible',
        buttonsStyling: false,
        reverseButtons: true,
      });
    },
    successSwal(data) {
      toastr({
        title: trans('words.done'),
        html: data.message,
        icon: data.status,
        showCancelButton: true,
        allowOutsideClick: true,
        cancelButtonText: trans('words.close'),
        confirmButtonText: trans('words.close'),
        confirmButtonClass: 'btn btn-default btn-lg float-right',
        cancelButtonClass: 'btn btn-default btn-lg float-left invisible',
        buttonsStyling: false,
        reverseButtons: true
      });
    },
    errorSwal(data) {
      try {
        let errorsHtml = '';
        $.each(data.errors, function (key, value) {
          errorsHtml += '<li>' + value[0] + '</li>';
        });
        if (data.message !== '') {
          let span = document.createElement('span');
          span.innerHTML = data.message;
          toastr({
            title: trans('words.error'),
            html: span,
            icon: data.status,
            showCancelButton: true,
            confirmButtonClass: 'btn btn-default btn-lg float-right',
            cancelButtonClass: 'btn btn-default invisible btn-lg float-left',
            buttonsStyling: false,
            confirmButtonText: 'Fechar',
            reverseButtons: true
          });
        } else {
          let span = document.createElement('span');
          span.innerHTML = errorsHtml;
          toastr({
            title: trans('words.error'),
            html: span,
            icon: data.status,
            showCancelButton: true,
            confirmButtonClass: 'btn btn-default btn-lg float-right',
            cancelButtonClass: 'btn btn-default invisible btn-lg float-left',
            buttonsStyling: false,
            confirmButtonText: 'Fechar',
            reverseButtons: true
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    printDocument(elem) {
      // $("table tbody th, table tbody td").wrapInner("<div></div>");
      var contents = $('#' + elem).html();
      var frame1 = $('<iframe />');
      frame1[0].name = 'frame1';
      frame1.css({
        'position': 'absolute',
        'top': '-1000000px'
      });
      $('body').append(frame1);
      var frameDoc = frame1[0].contentWindow ? frame1[0].contentWindow :
        frame1[0].contentDocument.document ? frame1[0].contentDocument.document :
        frame1[0].contentDocument;
      frameDoc.document.open();
      // Create a new HTML document.
      frameDoc.document.write('<html><body>');
      // Append the external CSS file.
      frameDoc.document.write(
        '<link href="/css/app.css" rel="stylesheet" type="text/css" />'
      );
      frameDoc.document.write('<style>html{font-size: 10px !important} @page {size: 7in 9.25in;margin:-2%;}</style>');

      // frameDoc.document.write('<style>fieldset {width: 100%;margin: 5px auto;color: #444;border: 3px solid #ccc;font-family: Arial;padding: 15px;}h2 {text-align: center;}p.sub-titulo {font-size: 20px;}.direita {text-align: right;}.text-center {text-align: center}.center {text-align: center;}</style>');

      // Append the DIV contents.
      frameDoc.document.write(contents);
      frameDoc.document.write('</body></html>');
      frameDoc.document.close();
      setTimeout(function () {
        const newLocal = 'frame1';
        window.frames[newLocal].focus();
        window.frames[newLocal].print();
        frame1.remove();
      }, 500);

      return true;
    },
    printDocumentCanvas(p1, p2, p3) {
      // $("table tbody th, table tbody td").wrapInner("<div></div>");
      // alert(document.getElementById("p2").childNodes);
      // console.log(document.getElementById("p2").childNodes);
      let header = $('#' + p1).html();
      let canvas = '<br><img src=\'' + document.getElementById('p2').childNodes[1].toDataURL() + '\'/>';
      let footer = $('#' + p3).html();
      let frame1 = $('<iframe />');

      frame1[0].name = 'frame1';
      // frame1.css({ "position": "absolute", "top": "-1000000px" });
      $('body').append(frame1);
      let frameDoc = frame1[0].contentWindow ? frame1[0].contentWindow :
        frame1[0].contentDocument.document ? frame1[0].contentDocument.document :
        frame1[0].contentDocument;
      frameDoc.document.open();
      // Create a new HTML document.
      frameDoc.document.write('<html><body>');
      // Append the external CSS file.
      frameDoc.document.write(
        '<link href="/css/app.css" rel="stylesheet" type="text/css" />'
      );
      frameDoc.document.write('<style>fieldset {width: 100%;margin: 5px auto;color: #444;border: 3px solid #ccc;font-family: Arial;padding: 15px;}h2 {text-align: center;}p.sub-titulo {font-size: 20px;}.direita {text-align: right;}.text-center {text-align: center}.center {text-align: center;}</style>');

      // Append the DIV contents.
      frameDoc.document.write(header);
      frameDoc.document.write(canvas);
      frameDoc.document.write(footer);
      frameDoc.document.write('</body></html>');
      frameDoc.document.close();
      setTimeout(function () {
        const newLocal = 'frame1';
        window.frames[newLocal].focus();
        window.frames[newLocal].print();
        frame1.remove();
      }, 500);

      return true;
    },
    printDocumentVertical(elem) {
      // $("table tbody th, table tbody td").wrapInner("<div></div>");
      var contents = $('#' + elem).html();
      var frame1 = $('<iframe />');
      frame1[0].name = 'frame1';
      // frame1.css({ "position": "absolute", "top": "-1000000px" });
      $('body').append(frame1);
      var frameDoc = frame1[0].contentWindow ? frame1[0].contentWindow :
        frame1[0].contentDocument.document ? frame1[0].contentDocument.document :
        frame1[0].contentDocument;
      frameDoc.document.open();
      // Create a new HTML document.
      frameDoc.document.write('<html><body>');
      frameDoc.document.title = 'Print page title';
      // Append the external CSS file.
      frameDoc.document.write('<style>@media print{@page {size: landscape; margin: 4mm}} @page { size: auto;   }</style>');
      frameDoc.document.write('<style>' +
        'thead tr th:first-child,' +
        'tbody tr td:first-child {' +
        'width: 260px;' +
        'min-width: 260px;' +
        'max-width: 260px;' +
        'word-break: break-all;' +
        '}' +
        'td, th, tr {' +
        'height: 1px !important;}' +
        '</style>');
      frameDoc.document.write(
        '<link href="/css/app.css" rel="stylesheet" type="text/css" />'
      );
      frameDoc.document.write('<style>fieldset {width: 100%;margin: 5px auto;color: #444;border: 3px solid #ccc;font-family: Arial;padding: 15px;}h2 {text-align: center;}p.sub-titulo {font-size: 20px;}.direita {text-align: right;}.text-center {text-align: center}.center {text-align: center;}</style>');

      // Append the DIV contents.
      frameDoc.document.write(contents);
      frameDoc.document.write('</body></html>');
      frameDoc.document.close();
      setTimeout(function () {
        const newLocal = 'frame1';
        window.frames[newLocal].focus();
        window.frames[newLocal].print();
        frame1.remove();
      }, 500);

      return true;
    },
    printPDF(response) {
      let frame1 = $('<iframe />');
      frame1[0].name = 'frame1';
      $('body').append(frame1);
      let frameDoc = frame1[0].contentWindow ?
        frame1[0].contentWindow :
        frame1[0].contentDocument.document ?
        frame1[0].contentDocument.document :
        frame1[0].contentDocument;
      frameDoc.document.open();
      frameDoc.document.write(response);
      frameDoc.document.close();
      setTimeout(function () {
        window.frames.frame1.focus();
        window.frames.frame1.print();
        frame1.remove();
      }, 500);
    },
    allow(task) {
      // return task;
      return this.$store.state.user.data.permissoes.findIndex(x => x === task) !== -1;
    },
    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    },
    getFormData(object) {
      const formData = new FormData();
      Object.keys(object).forEach(key => formData.append(key, object[key]));
      return formData;
    },
    buildFormData(formData, data, parentKey) {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
          this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
      } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
      }
    },
    jsonToFormData(data) {
      const formData = new FormData();

      this.buildFormData(formData, data);

      return formData;
    },
    getStatusFinanceiro(element) {
      switch (element.fin_status) {
        case -1:
          return `<span class="badge bg-red">${trans('words.in_arrears')}</span>`;
        case 0:
          return `<span class="badge bg-green">${trans('words.received')}</span>`;
        case 1:
          return `<span class="badge bg-orange">${trans('words.opened')}</span>`;
        case 2:
          return `<span class="badge bg-green">${trans('words.payed')}</span>`;
      }
    },
    formatXml(xml, tab) { // tab = optional indent value, default is tab (\t)
      var formatted = '',
        indent = '';
      tab = tab || '\t';
      xml.split(/>\s*</).forEach(function (node) {
        if (node.match(/^\/\w/)) indent = indent.substring(tab.length); // decrease indent by one 'tab'
        formatted += indent + '<' + node + '>\r\n';
        if (node.match(/^<?\w[^>]*[^/]$/)) indent += tab; // increase indent
      });
      return formatted.substring(1, formatted.length - 3);
    },
    sumArray(arr, key) {
      return arr.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
    sumArrayObject(arr, keyA, keyB) {
      return arr.reduce((a, b) => parseFloat(a) + (parseFloat(b[keyA][keyB]) || 0), 0);
    },
    generateUuid() {
      let dt = new Date().getTime();
      const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return uuid;
    },
    createUUID() {
      // http://www.ietf.org/rfc/rfc4122.txt
      let s = [];
      let hexDigits = "0123456789abcdef";
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";

      let uuid = s.join("");
      return uuid;
    },
    getTipoAssinatura(payload, assinado) {
      let evtPendente = [
        "Assinar",
        "Aprovar",
        "Reconhecer",
        "Assinar como parte",
        "Assinar como testemunha",
        "Assinar como interveniente",
        "Acusar recebimento",
        "Dar ciência",
      ];
      let evtFinalizado = [
        "Assinou",
        "Aprovou",
        "Reconheceu",
        "Assinou como parte",
        "Assinou como testemunha",
        "Assinou como interveniente",
        "Acusou recebimento",
        "Deu ciência",
      ];
      if (!assinado) {
        return evtPendente[parseInt(payload) - 1];
      }
      return evtFinalizado[parseInt(payload) - 1];
    },
    getCidades(payload) {
      if (!['', undefined, null].includes(payload)) {
        this.cidades = this.allCities.filter(x => x.state_id == payload);
      } else {
        this.cidades = [];
      }
      this.resetCity();
    },
    getPrint() {
      let elem = [...document.querySelectorAll('.badge')]
      if (elem.length)
        elem.map(el => {
          el.classList.add('border-0')
          el.style = ('color: #343a40 !important');
        })
      window.print();
      if (elem.length)
        elem.map(el => {
          el.classList.remove('border-0')
          el.style = '';
        })
    }
  }
};
export default common;