import axios from 'axios';

const state = {
    data: [],
    select: [],
    orderables: [{
            title: 'Nome',
            name: 'name'
        },
        {
            title: 'Cadastrado em',
            name: 'created_at'
        }
    ],
    filterGroups: [{
        name: 'Server',
        filters: [{
                title: 'Nome',
                name: 'nome',
                type: 'string'
            },
            {
                title: 'Cadastrado em',
                name: 'created_at',
                type: 'datetime'
            }
        ]
    }]
};

const mutations = {
    SET_SERVER: (state, payload) => {
        state.data = payload;
    },
    SET_SERVER_SELECT: (state, payload) => {
        state.select = payload;
    },
};

const actions = {
    setServers: (context, payload) => {
        context.commit('SET_SERVER', payload);
    },
    setServersDeleted: (context, payload) => {
        context.commit('SET_SERVER_DELETED', payload);
    },
    storeServer: (context, payload) => {
        return axios.post('/api/server/cadastrar', payload)
            .then(response => {
                return response.data;
            }).catch(error => {
                return error;
            });
    },
    updateServer: (context, payload) => {
        return axios.put(`/api/server/${payload.id}/editar`, payload).then(response => {
            return response.data;
        }).catch(error => {
            return error;
        });
    },
    getServerById: (context, payload) => {
        return axios.get(`/api/server/${payload}/detalhes`).then(response => {
            return response.data;
        }).catch(error => {
            return error;
        });
    }, 
};
export default {
    state,
    mutations,
    actions
};