const crud = {
  methods: {
    updateNotification(request) {
      if (!Swal.isVisible()) {
        switch (request.not_status) {
          case 1:
            toastr({
              icon: 'success',
              title: request.not_description
            });
            break;
          case 2:
            toastr({
              icon: 'warning',
              title: request.not_description
            });
            break;
          case 3:
            toastr({
              icon: 'error',
              title: request.not_description
            });
            break;
        }
      }
      this.$store.dispatch('newUserNotification', request);
    },
    updateUser() {
      this.$store.dispatch('getUserPermission');
    },
    prepareComponent() {
      this.$refs.filter.fetch();
    },
    getLayout() {
      let mySkins = [
        'skin-blue',
        'skin-black',
        'skin-red',
        'skin-yellow',
        'skin-purple',
        'skin-green',
        'skin-blue-light',
        'skin-black-light',
        'skin-red-light',
        'skin-yellow-light',
        'skin-purple-light',
        'skin-green-light'
      ];

      $.each(mySkins, function (i) {
        $('body').removeClass(mySkins[i]);
      });
      $('body').addClass(this.$store.state.user.localSettings.user_skin);

      switch (this.$store.state.user.localSettings.user_skin) {
        case 'skin-blue':
        case 'skin-blue-light':
          this.$store.commit('SET_LAYOUT', 'primary');
          break;
        case 'skin-red':
        case 'skin-red-light':
          this.$store.commit('SET_LAYOUT', 'danger');
          break;
        case 'skin-green':
        case 'skin-green-light':
          this.$store.commit('SET_LAYOUT', 'success');
          break;
        case 'skin-yellow':
        case 'skin-yellow-light':
          this.$store.commit('SET_LAYOUT', 'warning');
          break;
        case 'skin-purple':
        case 'skin-purple-light':
          this.$store.commit('SET_LAYOUT', 'purple');
          break;
        case 'skin-black':
        case 'skin-black-light':
          this.$store.commit('SET_LAYOUT', 'secondary');
          break;
      }
    },
    getColor() {
      switch (this.$store.state.user.localSettings.user_skin) {
        case 'skin-blue-light':
        case 'skin-blue':
          return '#007bff';
        case 'skin-red-light':
        case 'skin-red':
          return '#dc3545';
        case 'skin-green-light':
        case 'skin-green':
          return '#28a745';
        case 'skin-yellow-light':
        case 'skin-yellow':
          return '#ffc107';
        case 'skin-purple-light':
        case 'skin-purple':
          return '#605ca8';
        case 'skin-black-light':
        case 'skin-black':
          return '#333333';
      }
    },
    storeData(action, object) {
      this.$store
        .dispatch(action, object)
        .then(response => {
          this.loading = false;
          if (response.status === 'success') {
            toastr({
              icon: response.status,
              title: response.message
            });
            this.resetForm();
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    updateData(action, object, route) {
      this.$store
        .dispatch(action, object)
        .then(response => {
          this.loading = false;
          if (response.status === 'success') {
            toastr({
              icon: response.status,
              title: response.message
            });
            this.$router.push(route);
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    getById(action) {
      return this.$store
        .dispatch(action, this.$route.params.id)
        .then(response => {
          return response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    resetForm() {
      Object.assign(this.$data, this.$options.data());
      this.$validator.reset();
    },
    logout() {
      this.loading = true;
      this.$auth.logout()
        .then(response => {
          $('.modal').modal('hide');
          toastr({
            icon: 'success',
            title: trans('message.session_closed')
          });
          this.loading = false;
          setTimeout(() => {
            this.$router.push({
              name: 'login',
              params: {
                success: trans('message.logout_success')
              }
            });
          }, 500);
        })
        .catch(error => {
          console.log(error);
        });
    },
    closeModal() {
      $('#ModalLogout').hide(200);
    }
  }
}
export default crud;