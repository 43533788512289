/* eslint-disable no-undef */

const filterCrud = {
  methods: {
    disableData(id) {
      let request = {};
      request.id = id;
      request.route = this.filterable.model;
      this.$store.dispatch('disableData', request).then(response => {
        if (response.status === 'success') {
          this.$store.dispatch(this.filterable.storeAction, request);
        }
        toastr({
          icon: response.status,
          title: response.message
        });
      });
    },
    detailsObject(payload) {
      this.objectDetails = payload;
      $('#ModalShowData').modal('toggle');
    },
    deleteData(id, name) {
      let text = document.createElement('span');
      text.innerHTML =
        this.$t('message.confirm_delete') +
        '<br><strong style=\'word-wrap: break-word;\'>' +
        name +
        '</strong> ? </small><br><br>';
      Swal.fire({
        title: this.$t('words.atention'),
        html: text,
        icon: 'question',
        allowOutsideClick: true,
        showCancelButton: true,
        cancelButtonText: trans('words.close'),
        confirmButtonText: 'Confirmar',
        confirmButtonClass: 'btn btn-danger btn-lg float-right',
        cancelButtonClass: 'btn btn-default btn-lg float-left',
        buttonsStyling: false,
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: (confirm) => {
          if (confirm) {
            let request = {};
            request.id = id;
            request.route = this.filterable.model;
            request.name = name;
            return this.$store.dispatch('deleteData', request);
          }
        }
      }).then(response => {
        let text = document.createElement('span');
        text.innerHTML = response.value.message;
        if (response.value.status === 'success') {
          Swal.fire({
            icon: response.value.status,
            title: 'Feito',
            html: text,
            showCancelButton: true,
            confirmButtonClass: 'btn btn-default btn-lg float-right',
            cancelButtonClass: 'btn btn-default invisible btn-lg float-left',
            buttonsStyling: false,
            confirmButtonText: 'Fechar',
            reverseButtons: true
          });
          this.prepareComponent();
        } else {
          console.log(response);
          if (response.status !== 401) {
            Swal.fire({
              icon: response.value.status,
              title: 'Ops',
              html: response.value.message,
              showCancelButton: true,
              confirmButtonClass: 'btn btn-default btn-lg float-right',
              cancelButtonClass: 'btn btn-default invisible btn-lg float-left',
              buttonsStyling: false,
              confirmButtonText: 'Fechar',
              reverseButtons: true
            });
          } else {
            text.innerHTML = `<b>${response.message}</b>`;
            Swal.fire({
              icon: response.value.status,
              title: 'Houve um erro..',
              html: text,
              showCancelButton: true,
              confirmButtonClass: 'btn btn-default btn-lg float-right',
              cancelButtonClass: 'btn btn-default invisible btn-lg float-left',
              buttonsStyling: false,
              confirmButtonText: 'Fechar',
              reverseButtons: true
            });
          }
        }
      });
    },
    deletePermanent(id, name) {
      let text = document.createElement('span');
      text.innerHTML =
        this.$t('message.confirm_delete_alert') +
        '<br><br><small>' +
        this.$t('message.confirm_delete') +
        '<br><strong style=\'word-wrap: break-word;\'>' +
        name +
        '</strong> ? </small><br><br>';

      Swal.fire({
        title: this.$t('words.atention'),
        html: text,
        icon: 'warning',
        allowOutsideClick: true,
        showCancelButton: true,
        cancelButtonText: trans('words.close'),
        confirmButtonText: 'Confirmar',
        confirmButtonClass: 'btn btn-danger btn-lg float-right',
        cancelButtonClass: 'btn btn-default btn-lg float-left',
        buttonsStyling: false,
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: (confirm) => {
          if (confirm) {
            let request = {};
            request.id = id;
            request.route = this.filterable.model;
            request.name = name;
            return this.$store.dispatch('deleteData', request);
          }
        }
      }).then(response => {
        let text = document.createElement('span');
        text.innerHTML = response.value.message;
        if (response.value.status === 'success') {
          Swal.fire({
            icon: response.value.status,
            title: 'Feito',
            html: text,
            showCancelButton: true,
            cancelButtonClass: 'btn btn-default invisible btn-lg float-left',
            confirmButtonClass: 'btn btn-default btn-lg float-right',
            buttonsStyling: false,
            confirmButtonText: 'Fechar',
            reverseButtons: true
          });
          this.prepareComponent();
        } else {
          if (response.status !== 401) {
            Swal.fire({
              icon: response.value.status,
              title: 'Ops',
              html: response.value.message,
              showCancelButton: true,
              confirmButtonClass: 'btn btn-default btn-lg float-right',
              cancelButtonClass: 'btn btn-default invisible btn-lg float-left',
              buttonsStyling: false,
              confirmButtonText: 'Fechar',
              reverseButtons: true
            });
          } else {
            text.innerHTML = `<b>${response.message}</b>`;
            Swal.fire({
              icon: response.value.status,
              title: 'Houve um erro..',
              html: text,
              showCancelButton: true,
              confirmButtonClass: 'btn btn-default btn-lg float-right',
              cancelButtonClass: 'btn btn-default invisible btn-lg float-left',
              buttonsStyling: false,
              confirmButtonText: 'Fechar',
              reverseButtons: true
            });
          }
        }
      });
    },
    prepareComponent() {
      this.$refs.filter.fetch();
    },
    changeLoading() {
      this.filterable.loading = !this.filterable.loading;
    },
    recoveryData(id) {
      let request = {};
      request.id = id;
      request.route = this.filterable.model;
      this.$store.dispatch('recoveryData', request)
        .then(response => {
          if (response.status === 'success') {
            this.prepareComponent();
          }
          toastr({
            icon: response.status,
            html: response.message
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    handdleCollumn(payload) {
      this.filterable.headers[payload].visible = !this.filterable.headers[payload].visible;
    },
    checkCollumnVisible(name) {
      let visible = false;
      this.filterable.headers.map(item => {
        if (item.name === name) {
          visible = item.visible;
        }
      });
      return visible;
    }
  }
}
export default filterCrud;