/* eslint-disable no-undef */
export default {
  searchCnpj(cnpj) {
    cnpj = cnpj.replace(/\./g, '').replace('-', '').replace('/', '');
    if (cnpj.length < 14) {
      return;
    }
    $.ajaxSetup({
      type: 'GET',
      timeout: 3000,
      url: `https://www.receitaws.com.br/v1/cnpj/${cnpj}`
    });
    return $.ajax({
      dataType: 'JSONP'
    }).then(response => {
      return response;
    }).catch(error => {
      return error;
    });
  },
  searchZip(cep) {
    cep = cep.replace(/\./g, '').replace('-', '').replace('/', '');
    $.ajaxSetup({
      type: 'GET',
      timeout: 3000,
      url: `https://viacep.com.br/ws/${cep}/json/`
    });
    return $.ajax({
      dataType: 'JSONP'
    }).then(response => {
      return response;
    }).catch(error => {
      return error;
    });
  }
};