export default {
  route: {
    404: 'Página não encontrada',
    451: 'Termos de uso',
    dashboard: 'Inicio',
    companies: 'Empresas',
    companyCreate: 'Cadastrar Empresa',
    companyEdit: 'Editar Empresa',
    postCreate: 'Cadastrar Cargo',
    postEdit: 'Editar Cargo',
    planoCreate: 'Cadastrar Plano',
    planoEdit: 'Editar Plano',
    planos: 'Planos',
    termoCreate: 'Cadastrar Termo',
    termoEdit: 'Editar Termo',
    termos: 'Termos',
    posts: 'Cargos',
    users: 'Usuários',
    arquivoDeleted: 'Arquivos Deletados',    
    nivelCreate: 'Cadastrar Nivel',
    userCreate: 'Cadastrar Usuário',
    nivelEdit: 'Editar Nivel',
    niveis: 'Niveis',
    inbox: 'Para assinar',
    history: 'Histórico',
    auditoria: 'Auditoria',
    parametros: 'Parâmetros',
    sms: 'Validação via SMS',
    profile: 'Perfil',
    cofre: 'Documentos'
  },
  validation: {
    max: (field, value) => `Ops, ${field} não precisa ser maior que ${value}. Por favor, você pode verificar isso?`,
    max_value: (field, value) => `Ops, ${field} não precisa ser maior que ${value}. Por favor, você pode verificar isso?`,
    min: (field, value) => `Ops, ${field} deve ser maior ou igual a ${value}. Por favor, você pode verificar isso?`,
    //min_value: (field, value) => `Ops, ${field} deve ser maior ou igual a ${value}. Por favor, você pode verificar isso?`,
    min_value: (field, value) => `Deve ser maior ou igual que ${value}.`,
    accepted: (field) => `O campo ${field} precisa ser aceito.`,
    recaptcha: (field) => `O campo ${field} não está correto.`,
    active_url: (field) => `O campo ${field} infelizmente não é uma URL válida.`,
    after: (field, value) => `O campo ${field} precisa ser uma data depois de ${value}. Por favor, você pode verificar isso?`,
    after_or_equal: (field) => `<b>${field}</b> precisa ser uma data posterior ou igual a <b>:date</b>. Por favor, você pode verificar isso?`,
    alpha: (field) => `O campo ${field} deve conter somente letras. Por favor, você pode verificar isso?`,
    alpha_dash: (field) => `O campo ${field} deve conter letras, números e traços. Por favor, você pode verificar isso?`,
    alpha_num: (field) => `O campo ${field} deve conter somente letras e números. Por favor, você pode verificar isso?`,
    array: (field) => `O campo ${field} precisa ser um array. Por favor, você pode verificar isso?`,
    before: (field) => `O campo ${field} precisa ser uma data antes de :date. Por favor, você pode verificar isso?`,
    before_or_equal: (field) => `O campo ${field} precisa ser uma data anterior ou igual a :date. Por favor, você pode verificar isso?`,
    boolean: (field) => `O campo ${field} precisa ser verdadeiro ou falso. Por favor, você pode verificar isso?`,
    confirmed: (field) => `A confirmação de ${field} não confere. Por favor, você pode verificar isso?`,
    date: (field) => `O campo ${field} infelizmente não é uma data válida. Por favor, você pode verificar isso?`,
    date_format: (field) => `O campo ${field} infelizmente não confere com o formato. Por favor, você pode verificar isso?`,
    different: (field) => `O campo ${field} e :other devem ser diferentes. Por favor, você pode verificar isso?`,
    digits: (field, value) => `O campo ${field} precisa ter ${value} dígitos. Por favor, você pode verificar isso?`,
    digits_between: (field) => `O campo ${field} precisa ter entre :min e :max dígitos. Por favor, você pode verificar isso?`,
    dimensions: (field) => `O campo ${field} tem dimensões de imagem inválidas. Por favor, você pode verificar isso?`,
    distinct: (field) => `O campo ${field} tem um valor duplicado. Por favor, você pode verificar isso?`,
    email: (field) => `O campo ${field} precisa ser um endereço de e-mail válido. Por favor, você pode verificar isso?`,
    exists: (field) => `O campo ${field} selecionado é inválido. Por favor, você pode verificar isso?`,
    file: (field) => `O campo ${field} precisa ser um arquivo. Por favor, você pode verificar isso?`,
    filled: (field) => `O campo ${field} é um campo requerido. Por favor, você pode verificar isso?`,
    image: (field) => `O campo ${field} precisa ser uma imagem. Por favor, você pode verificar isso?`,
    in: (field) => `O campo ${field} é inválido. Por favor, você pode verificar isso?`,
    in_array: (field) => `O campo ${field} não existe em :other. Por favor, você pode verificar isso?`,
    integer: (field) => `O campo ${field} precisa ser um inteiro. Por favor, você pode verificar isso?`,
    ip: (field) => `O campo ${field} precisa ser um endereço IP válido. Por favor, você pode verificar isso?`,
    json: (field) => `O campo ${field} precisa ser um JSON válido. Por favor, você pode verificar isso?`,
    mimes: (field) => `O campo ${field} precisa ser um arquivo do tipo: :values. Por favor, você pode verificar isso?`,
    mimetypes: (field) => `O campo ${field} precisa ser um arquivo do tipo: :values. Por favor, você pode verificar isso?`,
    not_in: (field) => `O ${field} informado é inválido. Por favor, você pode verificar isso?`,
    numeric: (field) => `O campo ${field} precisa ser um número. Por favor, você pode verificar isso?`,
    present: (field) => `O campo ${field} precisa ser presente. Por favor, você pode verificar isso?`,
    regex: (field) => `O formato do ${field} é inválido. Por favor, você pode verificar isso?`,
    //required: (field) => `O campo ${field} precisa ser informado. Por favor, você pode verificar isso?`,
    required: (field) => 'O campo precisa ser informado.',
    required_if: (field) => `O campo ${field} precisa ser informado quando :other é :value. Por favor, você pode verificar isso?`,
    required_unless: (field) => `O ${field} é necessário a menos que :other esteja em :values. Por favor, você pode verificar isso?`,
    required_with: (field) => `O campo ${field} precisa ser informado quando :values está presente. Por favor, você pode verificar isso?`,
    required_with_all: (field) => `O campo ${field} precisa ser informado quando :values estão presentes. Por favor, você pode verificar isso?`,
    required_without: (field) => `O campo ${field} precisa ser informado quando :values não está presente. Por favor, você pode verificar isso?`,
    required_without_all: (field) => `O campo ${field} precisa ser informado quando nenhum destes estão presentes: :values. Por favor, você pode verificar isso?`,
    same: (field) => `O campo ${field} e :other devem ser iguais.`,
    string: (field) => `Ops, ${field} precisa ser uma string.`,
    timezone: (field) => `Ops, ${field} precisa ser uma timezone válida.`,
    unique: (field) => `Ops, ${field}  já está em uso.`,
    uploaded: (field) => `Ops, ${field} falhou ao ser enviado.`,
    url: (field) => `O formato de ${field} é inválido.`,
    matriz_not_found: (field) => 'Ops, o <b>CNPJ da Matriz</b> não foi localizado na base de dados, você pode verificar isso?',
    matriz_cnpj_error: (field) => 'Ops, o <b>CNPJ da Matriz</b>  não pode ser igual ao da empresa que você está cadastrando, você pode verificar isso?',
    cnpj_invalid: (field) => 'Ops, o CNPJ informado é inválido. Por favor, você pode verificar isso? ',
    cpf_invalid: (field) => 'Ops, o CPF informado é inválido. Por favor, você pode verificar isso? ',
    cep_invalid: (field) => 'Ops, o CEP informado é inválido. Por favor, você pode verificar isso? ',
    wrong_date: (field) => 'Ops, a Data informada é inválida. Por favor, você pode verificar isso? ',
    wrong_plot: (field) => 'Ops, a Parcela não pode ser maior que 60. Por favor, você pode verificar isso?',
    req: '<label title="Este campo é obrigatório" style="color:red">*</label>',
    field_required: 'Campos marcados com <label style="color:red">*</label> são obrigatórios.',
    field_valid_success: (field) => 'Não encontramos erros com o :field informado.',
    please_check: 'Por favor, verifique.',
    field_invalid: (field) => `${field} inválido.`,
    _default: (field) => 'O campo é inválido.',
    field_valid: (field) => ':field Válido.',
    email_valid: (field) => 'E-mail disponível.',
    email_invalid: (field) => 'E-mail indisponível.',
    check_field: (field) => 'Verifique o :field.',
    check_field_2: (field) => 'Verifique a :field.',
    require: (field) => 'Por favor, preencha este campo.',
    require_select: 'Por favor, selecione um item desta lista.',
    require_day: 'Por favor, preencha este campo. Nº. entre 1-31',
    require_month: 'Por favor, preencha este campo. Nº. entre 1-12',
    require_email: (field) => 'Por favor, insira um E-mail válido.',
    composite_unique: (field) => `My fancy custom validation message for ${field}`,
    file_extension: (field) => 'Arquivo inválido. Por favor,verifique a extensão do <b>:name</b>. Extensão necessária: <b>.:ext</b> Extensão enviada: <b>.:exception</b>',
    document: 'Ops, o documento é inválido!',
    NFE: 'Ops, a Chave de Acesso é inválida!'

  },
  'attributes': {
    name: 'Nome',
    description: 'Descrição',
    code: 'Código',
    type: 'Tipo',
    date: 'Data',
    cnpj_matriz: 'CNPJ Matriz',
    user_email: 'Login',
    note: 'Observação',
    pem_email: 'E-mail',
    user_contato: 'Nome',
    email_type: 'Tipo e-mail',
    account: 'Conta',
    plan_account: 'Plano de Conta',
    pte_contato: 'Nome',
    user_ddi: 'DDI',
    city_user: 'Cidade',
    zip_user: 'CEP',
    address_user: 'Endereço',
    neighborhood_user: 'Bairro',
    state_user: 'Estado',
    number_user: 'Nº',
    plots: 'Parcelas',
    discont: 'Desconto',
    penalty: 'Multa',
    interest: 'Juros',
    lack: 'Carência',
    full_name: 'Nome Completo',
    document: 'Documento',
    ctps: 'Carteira de Trabalho',
    site: 'Site',
    social_network: 'Rede Social',
    channel: 'Canal',
    commission: 'Comissão',
    user: 'Usuário | Usuários',
    emp_matriz: 'CNPJ Matriz',
    phone: 'Telefone',
    cellphone: 'Celular',
    zip: 'CEP',
    country: 'País',
    state: 'Estado',
    neighborhood: 'Bairro',
    address: 'Endereço',
    number: 'Número',
    user_name: 'Nome Completo',
    user_phone: 'Telefone',
    user_cellphone: 'Celular',
    user_document: 'Documento',
    crt: 'CRT',
    nire: 'Nire',
    nire_date: 'Data Nire',
    state_inscription: 'Inscrição Estadual',
    city_inscription: 'Inscrição Municipal',
    cnae: 'CNAE',
    complement: 'Complemento',
    file: 'Arquivo',
    spouse: 'Cônjuge',
    contact: 'Contato',
    born_date: 'Data de Nascimento',
    city: 'Cidade | Cidades',
    branch: 'Ramal',
    post: 'Cargo',
    posts: 'Cargos',
    retype_password: 'Confirme a senha',
    'NomeContato': 'Nome do Usuário',
    'EmailContato': 'E-Mail',
    'EmailEmpresa': 'E-Mail',
    'email': 'E-Mail',
    'TelefoneContato': 'Telefone',
    'CelularContato': 'Celular',
    'password': 'Senha',
    'Senha': 'Senha',
    'NovaSenha': 'Nova Senha',
    'ConfirmarSenha': 'Confirmar nova senha',
    'CnpjMatriz': 'CNPJ Matriz',
    'cnpj': 'CNPJ',
    'CPF': 'CPF',
    'DataNire': 'Data Nire',
    'InscricaoMunicipal': 'Inscricao Municipal',
    'InscricaoEstadual': 'Inscricao Estadual',
    'Nire': 'Nire',
    'CodigoBanco': 'Código',
    'NomeBanco': 'Nome',
    'NomeConta': 'Nome Conta',
    'Banco': 'Banco',
    'Agencia': 'Agência',
    'Digito': 'Digíto',
    'Numero': 'Número',
    'Saldo': 'Saldo',
    'Valor': 'Valor',
    'Nome': 'Nome',
    'Dia': 'Dia',
    'Mes': 'Mês',
    'Ano': 'Ano',
    'Conta': 'Conta Bancária',
    'ContaCompensar': 'Conta a ser Compensada',
    'social_name': 'Razão Social',
    'SenhaEmpresa': 'Senha',
    'Tipo': 'Tipo',
    'TipoCarteira': 'Tipo Carteira',
    'TipoInscricao': 'Tipo Inscrição Estadual',
    'Classe': 'Classe',
    'Quantidade': 'Quantidade',
    'Periodo': 'Periodicidade',
    'DataPayment': 'Data de Pagamento',
    'FinVencimento': 'Vencimento da conta',
    'FinEmissao': 'Emissão da conta',
    'Vencimento': 'Vencimento',
    'CnaePrincipal': 'CNAE Principal',
    'StartDate': 'Data Inicial',
    'EndDate': 'Data Final',
    'Descricao': 'Descrição',
    'uni_sigla': 'Sigla',
    'uni_descricao': 'Descrição',
    'fk_uni_id': 'Unidade',
    'fk_grup_id': 'Grupo de Produto',
    'prd_tipo': 'Tipo',
    'TipoPagamento': 'Forma de Pagamento',
    'fantasy_name': 'Nome Fantasia',
    'RG': 'RG',
    'TipoContribuinte': 'Tipo Contribuinte',
    'tip_operacao': 'Tipo Operação',
    'ces_codigo': 'Código',
    'ces_ncm': 'NCM',
    'ces_categoria': 'Categoria',
    'ces_item': 'Item',
    'ces_descricao': 'Descrição',
    'cfo_codigo': 'Código',
    'g-recaptcha-response': 'Não sou um robô',
    'fdt_descricao': 'Nome',
    'feriado.fer_dia': 'Dia',
    'feriado.fer_mes': 'Mês',
    'feriado.fer_ano': 'Ano',
    language: 'Idioma',
    old_password: 'Senha antiga'
  },
  words: {
    hello: 'Olá',
    trial: 'Experimente Grátis',
    pes_documento: 'Documento',
    pes_razao: 'Nome',
    invalid_form: 'Formulário inválido.',
    invalid_form_text: 'Alguns campos do formulários estão inválidos..',
    successful: 'Acertado',
    register: 'Cadastrar',
    record: 'Registrar',
    address: 'Endereço',
    contact: 'Contato',
    register_a_new_membership: 'Registrar um novo membro',
    i_forgot_my_password: 'Esqueci minha senha',
    change_password: 'Alterar senha',
    change_photo: 'Alterar foto',
    change_photo_user: 'Alterar foto do perfil',
    remember_me: '   Lembrar-me',
    i_already_have_a_membership: 'Já sou um membro',
    sign_in: 'Entrar',
    user_settings: 'Perfil',
    log_out: 'Sair',
    other_data: 'Outros dados',
    toggle_navigation: 'Trocar navegação',
    login_message: 'Entre para iniciar uma nova sessão',
    register_message: 'Registrar um novo membro',
    password_reset_message: 'Recuperar senha',
    reset_password: 'Resetar senha',
    send_password_reset_link: 'Enviar link de recuperação de senha',
    atention: 'Atenção !',
    active: 'Ativo',
    access: 'Acessar',
    delete: 'Deletar',
    restore: 'Recuperar',
    create: 'Novo',
    create_new: 'Cadastrar um Novo',
    cancel: 'Cancelar',
    save: 'Salvar',
    include: 'Incluir',
    edit: 'Editar',
    create_company: 'Cadastrar nova Empresa',
    create_bank: 'Cadastrar novo Banco',
    delete_company: 'Deletar Empresa',
    delete_register: 'Deletar registro',
    delete_person: 'Deletar Pessoa',
    delete_bank: 'Deletar Banco',
    delete_bank_account: 'Deletar Conta Bancária',
    delete_plan_account: 'Deletar Plano de Conta',
    plan_account: 'Plano de Conta ',
    delete_condition_payment: 'Deletar Condição de Pagamento',
    delete_holiday: 'Deletar Feriado',
    hello: 'Olá',
    result: 'Resultado',
    member: 'Membro desde',
    account_settings: 'Configurações da Conta',
    account_plan: 'Planos de Conta',
    post_data: 'Dados do Cargo',
    account_data: 'Dados da Conta',
    ncm_data: 'Dados do NCM',
    cfop_data: 'Dados do CFOP',
    warehouse_data: 'Dados do Almoxarife',
    unity_data: 'Dados da Unidade',
    movement_type_data: 'Dados do Tipo de Movimento',
    group_data: 'Dados do Grupo de Produtos',
    import_data: 'Importar dados',
    import: 'Importar',
    export_data: 'Exportar dados',
    export: 'Exportar',
    account_data_bank: 'Dados da Conta Bancária',
    edit_data: 'Editar Dados',
    edit_account: 'Editar conta',
    delete_account: 'Excluir conta',
    compensate: 'Compensar',
    profile: 'Perfil',
    fields: 'Campos',
    cashier: 'Caixa',
    personal_data: 'Dados Pessoais',
    data_supervisor: 'Dados Fiscais',
    data_plan_account: 'Informações do Plano',
    data_contact: 'Dados do Contato - Usuário',
    data_city: 'Dados do Municipio',
    data_company: 'Dados da Empresa',
    company_address: 'Endereço da Empresa',
    personal_data_company: 'Dados da Pessoa Jurídica',
    old_password: 'Senha antiga:',
    type_old_password: 'Insira sua senha antiga',
    current_password: 'Senha atual',
    type_current_password: 'Insira sua senha atual',
    new_password: 'Nova Senha:',
    type_new_password: 'Nova senha (minímo 6 digítos)',
    confirm_new_password: 'Confirmar Nova Senha:',
    type_confirm_new_password: 'Confirme sua nova senha',
    confirm: 'Confirmar',
    details: 'Detalhes',
    show: 'Mostrar',
    hide: 'Esconder',
    weak: 'Fraca',
    medium: 'Média',
    strong: 'Forte',
    random_password: 'Gerar Senha',
    close: 'Fechar',
    closeAll: 'Fechar Todas',
    closeOthers: 'Fechar Outras',
    settings: 'Configurações',
    reports: 'Relatórios',
    report_dre: 'Relatório DRE',
    report_stock: 'Relatório de Estoque',
    registers: 'Cadastros',
    companies: 'Empresas',
    company: 'Empresa',
    branch_companies: 'Empresas Filiais',
    banks: 'Bancos',
    persons: 'Pessoas',
    person: 'Pessoa ',
    holidays: 'Feriados',
    holiday: 'Feriado',
    holidays_month: 'Feriados do mês',
    home: 'Início',
    day: 'Dia',
    pay_date: 'Data do Pagamento',
    receive_date: 'Data do Recebimento',
    days: 'Dias',
    month: 'Mês',
    monthly: 'Mensal',
    weekly: 'Semanal',
    week: 'Semana',
    yearly: 'Anual',
    fortnightly: 'Quinzenal',
    period: 'Período',
    year: 'Ano',
    description: 'Descrição',
    code: 'Código',
    number: 'Número',
    number_of_repetitions: 'Número de repetições',
    agency: 'Agência',
    balance: 'Saldo',
    previous_balance: 'Saldo Anterior',
    balance_after: 'Saldo Depois',
    current_balance: 'Saldo Atual',
    digit: 'Digíto',
    data_bank: 'Dados do Banco',
    data_register: 'Dados do Cadastro',
    data_account_plan: 'Informações do Plano de Conta',
    data_bank_account: 'Informações da Conta Bancaria',
    data_condition_payment: 'Informações da Condição de Pagamento',
    data_holidays: 'Informações do Feriado',
    state: 'Estado',
    person_document: 'CPF',
    type_person_document: 'Insira o CPF',
    person_document_company: 'CNPJ',
    type_cnpj: 'Insira o CNPJ',
    type_ie: 'Insira a Inscrição Estadual',
    create_user: 'Criar usuário',
    is_provider: 'É um fornecedor?',
    is_client: 'É um cliente?',
    is_user: 'É um usuário?',
    is_employee: 'É um funcionário?',
    type_branch: 'Insira o ramal',
    type_ddi: 'Insira o DDI',
    type_name: 'Insira o Nome',
    type_email: 'Insira o e-mail',
    type_email_contact: 'Insira o e-mail do Contato',
    type_address: 'Insira o endereço',
    type_state: 'Insira o estado',
    type_zip: 'Insira o CEP para busca',
    type_city: 'Insira a cidade',
    type_neighborhood: 'Insira o bairro',
    type_phone: 'Insira o telefone',
    type_cellphone: 'Insira o celular',
    type_social_name: 'Insira a Razão Social',
    cnae: 'CNAE Principal',
    nire: 'NIRE',
    date_nire: 'Data do Nire',
    state_inscription: 'Inscrição Estadual',
    city_inscription: 'Inscrição Municipal',
    simple_national: 'Simples Nacional',
    is_free: 'Sim, é ISENTO.',
    is_matriz: 'É Matriz?',
    yes_is_matriz: 'Sim, é uma empresa MATRIZ',
    fantasy_name: 'Nome Fantasia',
    is_free_inscription: 'Isento de Inscrição Estadual?',
    is_simple_national: 'Sim, é optante pelo SIMPLES.',
    type_of_registration: 'Tipo de Inscrição Estadual',
    type_type_registration: 'Insira o tipo de inscrição',
    type_cnae: 'Insira o CNAE',
    type_ctps: 'Insira a carteira de trabalho',
    type_social_network: 'Insira sua rede social',
    type_channel: 'Insira o canal',
    type_site: 'Insira seu site',
    type_cnpj_matriz: 'Insira o CNPJ da MATRIZ',
    type_fantasy_name: 'Insira o Nome Fantasia',
    type_nire: 'Insira o NIRE',
    type_state_inscription: 'Inscrição Estadual',
    type_city_inscription: 'Inscrição Municipal',
    type_password: 'Insira a Senha (minímo 6 digítos)',
    type_password_2: 'Insira sua senha de acesso',
    type_password_confirm: 'Confirme sua senha',
    type_agency: 'Digite a agência',
    type_number: 'Insira o número',
    type_number_account: 'Insira o número da conta',
    type_digit: 'Insira o digíto',
    type_balance: 'R$',
    type_day: 'Insira o dia',
    type_month: 'Insira o mês',
    type_year: 'Insira o ano',
    type_code: 'Insira o código',
    type_commission: 'Insira o valor da comissão',
    type_description: 'Insira uma descrição',
    type_sup_category: 'Insira categoria ou deixe em branco para cadastrar',
    more_info: 'Mais informações',
    accounts: 'Contas',
    financial_accounts: 'Contas Financeiras',
    bank: 'Banco',
    account: 'Conta',
    financial_operations: 'Operações Financeiras',
    person_simple: 'Pessoa Física',
    person_company: 'Pessoa Jurídica',
    note: 'Observação',
    type_note: 'Escreva uma observação',
    financial: 'Financeiro',  
    money_deposit: 'Contas a Receber',
    cash_outflow: 'Contas a Pagar',
    bank_statement: 'Extrato',
    cash_flow: 'Fluxo de Caixa',
    input: 'Entrada',
    output: 'Saída',
    rate: 'Taxa',
    emission: 'Emissão',
    maturity: 'Vencimento',
    new_invoice: 'Nova Fatura',
    visualize: 'Visualizar',
    value: 'Valor',
    open_value: 'Em Aberto',
    in_arrears: 'Em Atraso',
    original_value: 'Valor original',
    amount_received: 'Valor recebido',
    amount_receivable: 'A receber',
    payable: 'A pagar',
    value_of_quota: 'Valor da Parcela',
    status: 'Status',
    payer: 'Pagador',
    plots: 'Parcelas',
    plot: 'Parcela',
    payment_form: 'Forma de Pagamento',
    payment_forms: 'Formas de Pagamento',
    last_access: 'Último acesso',
    extra: 'Dados Adicionais',
    date_created: 'Data de Cadastro',
    search: 'Pesquisar',
    reset: 'Reiniciar',
    filter: 'Filtrar',
    btn_search_zip: 'Buscar endereço através do CEP',
    btn_search_cnpj: 'Buscar dados pelo CNPJ',
    payment_conditions: 'Condições de Pagamento',
    payment_condition: 'Condição de Pagamento',
    type: 'Tipo',
    class: 'Classe',
    top_category: 'Categoria Superior',
    sub_category: 'Sub Categoria',
    sub_categories: 'Sub Categorias',
    type_wallet: 'Tipo Carteira',
    type_initials: 'Insira uma sigla',
    operation: 'Operação',
    add_new: 'Cadastrar um novo',
    select: 'Selecione',
    tip: 'Dica',
    congratulations: 'Parabéns',
    amount: 'Quantidade',
    wallet: 'Carteira',
    date_payment: 'Pagamento',
    include_check: 'Incluir Cheque',
    mark_received: 'Marcar como Recebido',
    mark_as_payment: 'Marcar como Pago',
    account_open: 'EM ABERTO',
    ppay: 'BAIXADO PARCIALEMNTE',
    partial_pay: 'Baixado Parcialmente',
    opened: 'Em Aberto',
    account_received: 'CONTA RECEBIDA',
    account_payed: 'CONTA PAGO',
    received: 'Recebido',
    payed: 'Pago',
    paid_in: 'Pago em',
    operations: 'Operações',
    cancel_received: 'Estornar',
    cancel_payment: 'Estornar',
    generate_receipt: 'Emitir Recibo',
    generate_ticket: 'Gerar Boleto',
    created_at: 'Cadastrado em',
    updated_at: 'Atualizado em',
    deleted_at: 'Deletado em',
    cat_superior: 'Categoria Superior',
    category: 'Categoria',
    periodicity: 'Periodicidade',
    repeat: 'Repetir',
    delete_quota: 'Deletar Parcelas',
    quota: 'Parcela',
    total: 'Total',
    movements: 'Movimentos',
    movements_stock: 'Movimentos de Estoque',
    movement: 'Movimento',
    banking_movement: 'Movimento Bancário',
    banking_movements: 'Movimentos Bancários',
    outstanding_amount: 'Valor em Aberto',
    outstanding_amount_quota: 'Parcela em Aberto',
    outstanding_amount_total: 'Total em Aberto',
    value_total: 'Valor Total',
    cancel_single: 'Cancelar somente esse',
    cancel_all: 'Cancelar TODOS os registros relacionados',
    payment: 'Pagamento',
    payments: 'Pagamentos',
    by: 'por',
    receipt_of_payment: 'Recibo de Pagamento',
    received_from: 'Recebemos de ',
    received_from_single: 'Recebi de ',
    received_from_value: 'o pagamento de',
    regarding: 'referente a:',
    print: 'Imprimir',
    print_receipt: 'Imprimir Recibo',
    print_report: 'Imprimir Relatório',
    receiver: 'Recebedor',
    all_accounts: 'Todas as contas',
    credit: 'Crédito',
    debit: 'Débito',
    peoples: 'Pessoas',
    compensate_payment: 'Compensar Pagamento',
    select_account: 'Selecione a conta que deseja compensar',
    compensations: 'Compensações',
    account_bank: 'Conta Bancária',
    is_bond: '  está vinculado.',
    user_company: 'Abaixo, as empresas que o usuário ',
    back: 'Voltar',
    recovery_password: 'Recuperar Senha',
    send_email: 'Enviar e-mail',
    send: 'Enviar',
    send_charge: 'Enviar Cobrança',
    income: 'RECEITAS',
    billing: 'Faturamento',
    neutral: 'Neutro',
    expenses: 'Despesas',
    start_date: 'Data Inicial',
    end_date: 'Data Final',
    up_to: ' ao dia ',
    refresh: 'Atualizar',
    people_registers: 'Pessoas Cadastradas',
    report_issued_day: 'Relatório emitido dia: ',
    report_between_day: 'Relátorio entre o dia ',
    extract: 'Extrato',
    new_payment: 'Novo pagamento',
    new_receipt: 'Novo recebimento',
    balance_total: 'Saldo Total',
    revenue_expense_ratio: 'Relação Receita / Despesa',
    money: 'Dinheiro',
    credit_card: 'Cartão de Crédito',
    debit_card: 'Cartão de Débito',
    ticket: 'Boleto',
    check: 'Cheque',
    panel_access: 'Painel de Acesso',
    yes: 'Sim',
    no: 'Não',
    stock: 'Estoque',
    warehouse: 'Deposito',
    product_group: 'Grupo de Produto',
    details_data: 'Detalhes do cadastro',
    unity: 'Unidade',
    initials: 'Sigla',
    movement_type_stock: 'Tipos de Movimento de Estoque',
    product: 'Produto',
    products: 'Produtos',
    bar_code: 'Código de Barras',
    type_bar_code: 'Insira o código de barras',
    code_ncm: 'Código NCM',
    type_code_ncm: 'Insira o código NCM',
    word_ncm: 'Letra NCM',
    type_word_ncm: 'Insira a letra NCM',
    maximum_quantity: 'Quantidade máxima',
    max_quantity: 'Qtd Máxima',
    type_maximum_quantity: 'Escreva a quantidade máxima',
    minimum_quantity: 'Quantidade mínima',
    min_quantity: 'Qtd mínima',
    type_minimum_quantity: 'Escreva a quantidade mínima',
    cost_value: 'Valor de Custo',
    type_cost_value: 'Insira o valor de custo',
    sell_value: 'Valor de Venda',
    type_sell_value: 'Insira o valor de venda',
    net_weight: 'Peso Líquido',
    gross_weight: 'Peso Bruto',
    product_image: 'Imagem do produto',
    product_data: 'Dados do Produto',
    consumables: 'Material de Consumo',
    feedstock: 'Materia Prima',
    finished_product: 'Produto Acabado',
    intermediary_product: 'Produto Intermediario',
    others: 'Outros',
    word: 'Letra',
    image: 'Imagem',
    movement_type: 'Tipo de Movimento',
    simple_interest: 'Juros Simples',
    compound_interest: 'Juros Composto',
    simulation_condition: 'Simulação da Condição de Parcelamento',
    date_reference: 'Data de referência',
    reference_value: 'Valor de referência',
    refresh_simulation: 'Atualizar simulação',
    note_repeat: 'Até 60 Meses (5 Anos / 240 Semanas).',
    change_language: 'Alterar Linguagem',
    portuguese: 'Português',
    english: 'Inglês',
    choice: 'Escolha...',
    from_library: 'Da Biblioteca',
    from_disk: 'Do meu computador',
    stock_balance: 'Saldo Estoque',
    product_balance: 'Saldo Produto',
    select_picture: 'Selecione uma Imagem',
    dont_have_branches: 'Não possui filiais',
    show_history: 'Ver Histórico',
    show_balance: 'Ver Saldo',
    history_product: 'Histórico do Produto',
    is_fractional: 'Unidade Fracionada?',
    balance_per_lot: 'Saldo por Lote',
    lot_product: 'Lotes do Produto',
    input_product: 'Entrada de Produto',
    output_product: 'Saida de Produto',
    lot: 'Lote',
    type_amount: 'Insira a quantidade',
    type_lot_number: 'Insira o número do lote',
    select_warehouse: 'Selecione um almoxarifado',
    all: 'Todos',
    product_history: 'Histórico do Produto',
    lot_number: 'Número do Lote',
    lot_description: 'Descrição do Lote',
    lot_maturity: 'Vencimento do Lote',
    product_movements: 'Abaixo, os movimentos de estoque do produto',
    select_page: 'Selecione o número da página',
    select_page_size: 'Selecione a quantidade de resultados',
    options: 'Opções',
    new_input: 'Cadastrar nova entrada',
    new_output: 'Cadastrar nova saida',
    overview: 'Visão Geral',
    stock_report: 'Relatório Estoque',
    stock_mov_report: 'Relatório de Movimento de Estoque',
    per_product: 'Por Produto',
    per_product_group: 'Por Grupo de Produto',
    per_movement_type: 'Por Tipo de Movimento',
    informations: 'Informações',
    information: 'Informação',
    simplified: 'Simplificado',
    detailed: 'Detalhado',
    product_initial: 'Produto Inicial',
    product_final: 'Produto Final',
    balance_type: 'Tipo de saldo',
    any_balance: 'Todos os saldos',
    only_positive: 'Somente positivo',
    only_negative: 'Somente negativo',
    type_code_product: 'Insira o código do produto',
    most_popular_products: 'Produtos mais movimentados',
    less_moving_products: 'Produtos menos movimentados',
    view_more: 'Ver mais',
    view: 'Ver',
    product_zero_balance: 'Produtos com saldo zerado',
    product_lower_balance: 'Produtos com saldo abaixo do mínimo',
    company_settings: 'Configurações da Empresa',
    permissions: 'Permissões',
    profiles: 'Perfis',
    module: 'Módulo',
    processing: 'Processando...',
    please_wait: 'Por favor, aguarde.',
    at: 'às',
    logout: 'Desconectar',
    number_account: 'Número da Conta',
    rg: 'RG',
    type_RG: 'Insira o RG',
    taxpayer_type: 'Tipo de Contribuinte do ICMS',
    taxpayer: 'Contribuinte',
    non_contributing: 'Não Contribuinte',
    free: 'Isento',
    required: 'Requerido',
    ipi: 'IPI',
    beginn_validity: 'Início Vigência',
    end_validity: 'Fim Vigência',
    unity_description: 'Descrição da Unidade',
    supervisor: 'Fiscal',
    table_cest: 'Tabela C.E.S.T',
    table_ncm: 'Tabela NCM',
    table_cfop: 'Tabela CFOP',
    ncm: 'NCM',
    cfop: 'CFOP',
    item: 'Item',
    cest_code: 'Código C.E.S.T',
    origin: 'Origem',
    type_origin: 'Origem do produto',
    profit: 'Lucro',
    ipi_code: 'Código IPI',
    icms: 'ICMS',
    icms_code: 'Código ICMS',
    pis: 'PIS',
    pis_code: 'Código PIS',
    cofins: 'Cofins',
    cofins_code: 'Código Cofins',
    cest: 'CEST',
    select_ncm: 'Selecione um NCM (clicando no <i><a class="fa fa-check" ></a></i>) ',
    select_cest: 'Selecione um CEST (clicando no <i><a class="fa fa-check" ></a></i>) ',
    select_person: 'Selecione uma Pessoa (clicando no <i><a class="fa fa-check" ></a></i>) ',
    origin_0: 'Nacional',
    origin_1: 'Estr.(Importacao Direta)',
    origin_2: 'Estr.(Adquirida Merc. Interno)',
    origin_3: 'Nacional-Merc/Bem com conteúdo de importação superior a 40%',
    origin_4: 'Nacional, Prod. em conformidade com os procedimentos produtivos básicos',
    origin_5: 'Nacional-Merc/Bem com o conteúdo de importação inferior ou Igual a 40%',
    origin_6: 'Estrangeiro-Importação direta, sem similar nacional, consta na lista Camex',
    origin_7: 'Estrangeiro-Adq Internamente, sem similar nacional, consta na lista Camex',
    origin_8: 'Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%.',
    electronic_invoice: 'Nota Fiscal Eletrônica',
    electronic_invoice_deleted: 'Notas deletadas',
    invoice: '{0}Nota Fiscal |{2,Inf}Notas Fiscais',
    model: 'Modelo',
    model_55: '55 - Nota Fiscal Eletrônica',
    model_65: '65 - Cupom Fiscal',
    issuer: 'Emissor',
    client_type: 'Tipo de Cliente',
    reason: 'Motivo',
    reason_nfe_0: 'NFE Normal',
    reason_nfe_1: 'NFE Complementar',
    reason_nfe_2: 'NFE Ajuste',
    reason_nfe_3: 'NFE Devolução',
    access_key: 'Chave de Acesso',
    serie: 'Série',
    ie_st: 'Inscrição Estadual Subistituto Tributário',
    id_dest_1: '1 - Operação interna',
    id_dest_2: '1 - Operação interestadual',
    id_dest_3: '1 - Operação com exterior',
    tipo_emissao_1: 'Emissão normal (não em contingência)',
    tipo_emissao_2: 'Contingência FS-IA, com impressão do DANFE em formulário de segurança',
    tipo_emissao_3: 'Contingência SCAN (Sistema de Contingência do Ambiente Nacional)',
    tipo_emissao_4: 'Contingência DPEC (Declaração Prévia da Emissão em Contingência)',
    tipo_emissao_5: 'Contingência FS-DA, com impressão do DANFE em formulário de segurança',
    tipo_emissao_6: 'Contingência SVC-AN (SEFAZ Virtual de Contingência do AN)',
    tipo_emissao_7: 'Contingência SVC-RS (SEFAZ Virtual de Contingência do RS)',
    tipo_emissao_9: 'Contingência off-line da NFC-e',
    tipo_impressao_0: 'Sem geração de DANFE',
    tipo_impressao_1: 'DANFE normal, Retrato',
    tipo_impressao_2: 'DANFE normal, Paisagem',
    tipo_impressao_3: 'DANFE Simplificado',
    tipo_impressao_4: 'DANFE NFC-e',
    tipo_impressao_5: 'DANFE NFC-e em mensagem eletrônica',
    buyer_presence_indicator: 'Indicador de presença do Comprador',
    buyer_0: '0 - Não se Aplica',
    buyer_1: '1 - Operação Presencial',
    buyer_2: '2 - Operação não presencial, Internet',
    buyer_3: '3 - Operação não presencial, Teleatendimento',
    buyer_5: '5 - Operação Presencial, fora do Estabelecimento',
    buyer_9: '9 - Operação não presencial, Outros',
    basic_data: 'Dados Básicos',
    own: 'Próprio',
    the_3rd: 'Terceiros',
    client_final: 'Cliente Final',
    normal: 'Normal',
    disabled: 'Inativos',
    disable: 'Desativar',
    enable: 'Ativar',
    deleted: 'Deletados',
    excluded: 'Deletado!',
    done: 'Feito!',
    error: 'Ops, ocorreu um erro..',
    success: 'Sucesso !',

    type_complement: 'Insira um Complemento (Opcional)',
    country: 'País',
    type_country: 'Insira o País',
    crt: 'Código de Regime Tributário',
    crt_1: 'Simples Nacional',
    crt_2: 'Simples Nacional, excesso sublimite de receita bruta',
    crt_3: 'Regime Normal',
    notification: 'Notificação',
    notifications: 'Notificações',
    delete_all: 'Deletar todas',
    internal: 'Interno',
    post_internal: 'Cargo Interno ?',
    object_data: 'Dados do :object',
    object_data_2: 'Dados da :object',
    is_shipping_company: 'É uma transportadora?',
    customer: '{0} Cliente|[2,inf] Clientes',
    provider: '{0} Fornecedor|[2,inf] Fornecedores',
    shipping: '{0} Transportadora|[2,inf] Transportadoras',
    example_pluralization: '{0} no comments yet|{1} 1 comment|[2, inf] :count comments',
    type_document: 'Insira o documento',
    hugs: 'Abraços',
    mbd_team: 'Equipe MBD Informática',
    mesor_region: 'Mesor Região',
    micro_region: 'Micro Região',
    state_uf: 'Sigla Estado',
    cod: 'Código :object',
    next_nfe: 'Próxima nota a ser emitida',
    nfe_serie: 'Série de Nota',
    nfe_series: 'Séries NF-e',
    type_next_nfe: 'Insira o numero da próxima nota',
    register_serie_nfe: 'Cadastrar Série de Nota',
    update_serie_nfe: 'Editar Série de Nota Fiscal',
    others_nfe_informations: 'Outras Informações da Nota Fiscal',
    next: 'Próximo',
    return: 'Voltar',
    report_nfe_itens: 'Informe os items da Nota Fiscal',
    add: 'Adicionar',
    see_details: 'Ver detalhes',
    less_details: 'Menos detalhes',
    qtd: 'Qtd.',
    value_unity: 'Valor Unit.',
    freight: 'Frete',   
    freight_0: '0 - Contratação do Frete por conta do Remetente (CIF)',
    freight_1: '1 - Contratação do Frete por conta do Destinatário (FOB)',
    freight_2: '2 - Contratação do Frete por conta de Terceiros',
    freight_3: '3 - Transporte Próprio por conta do Remetente',
    freight_4: '4 - Transporte Próprio por conta do Destinatário',
    freight_9: '9 - Sem Ocorrência de Transporte',
    safe: 'Seguro',
    other_expenses: 'Outras despesas',
    delivery: 'Entrega',
    amount_vol_transported: 'Quantidade de vol. transportados',
    type_many_volumes: 'Informe quantos volumes',
    type_vol_transported: 'Tipo de vol. transportados',
    ex_vol_transported: 'Ex: Caixa, pacotes, barril',
    total_gross_weight: 'Peso bruto total (KG)',
    total_net_weight: 'Peso líquido total (KG)',   
    cost_of_freight: 'Valor do frete',
    rntc: 'RNTC do veículo (Opcional)',
    type_rntc: 'Insira o Registro Nacional de Transportador de Carga',
    veihcle_plate: 'Placa do veículo',
    vol_identification_number: 'Nº de identificação dos volumes',
    identification_number: 'Número de identificação',
    confirm_the_data: 'Confirme os Dados',
    person_type: 'Tipo de Pessoa',
    abstract: 'Resumo',
    almost_there: 'Quase lá...',
    nfe_msg_0: 'Confirme os dados da sua Nota Fiscal Eletrônica, caso esteja tudo certo, basta clicar em "Salvar".',
    nfe_msg_1: 'Caso queira corrigir os dados, fique à vontade para voltar as etapas.',
    nfe_status_0: 'Não Transmitida ',
    nfe_status_1: 'NF-e Transmitida',
    nfc_status_1: 'NFC-e Transmitida',
    nfe_status_2: 'Em Cancelamento',
    nfe_status_3: 'NF-e Cancelada  ',
    nfc_status_3: 'NFC-e Cancelada  ',
    nfe_status_4: 'Cancelada fora de prazo',
    nfe_status_5: 'Em processamento',
    nfe_status_6: 'Falha no Cancelamento',
    nfe_status_8: 'Uso Denegado',
    nfe_status_7: 'NFC-e Inutilizada  ',
    nfe_status_9: 'Não Autorizada',
    prev_status_0: 'Aguardando ',
    prev_status_1: 'Aprovada ',
    prev_status_2: 'Recusada',
    prev_status_3: 'Cancelada',
    date_cancelment: 'Data de Cancelamento',
    date_transmission: 'Data de Transmissão',
    digital_certificate: 'Certificado Digital',
    cod_ipi_00: 'Entrada com recuperação de crédito',
    cod_ipi_49: 'Outras entradas',
    cod_ipi_50: 'Saída tributada',
    cod_ipi_99: 'Outras saídas',
    cod_ipi_01: 'Entrada tributada com alíquota zero',
    cod_ipi_02: 'Entrada isenta',
    cod_ipi_03: 'Entrada não-tributada',
    cod_ipi_04: 'Entrada imune',
    cod_ipi_05: 'Entrada com suspensão',
    cod_ipi_51: 'Saída tributada com alíquota zero',
    cod_ipi_52: 'Saída isenta',
    cod_ipi_53: 'Saída não-tributada',
    cod_ipi_54: 'Saída imune',
    cod_ipi_55: 'Saída com suspensão',
    cod_icms_00: 'Tributada integralmente',
    cod_icms_10: 'Tributada e com cobrança do ICMS por substituição tributária',
    cod_icms_20: 'Com redução de base de cálculo',
    cod_icms_30: 'Isenta ou não tributada e com cobrança do ICMS por substituição tributária',
    cod_icms_40: 'Isenta',
    cod_icms_41: 'Não tributada',
    cod_icms_50: 'Suspensão',
    cod_icms_51: 'Diferimento',
    cod_icms_60: 'ICMS cobrado anteriormente por substituição tributária',
    cod_icms_70: 'Com redução de base de cálculo e cobrança do ICMS por substituição tributária',
    cod_icms_101: 'Tributada pelo Simples Nacional com permissão de crédito',
    cod_icms_102: 'Tributada pelo Simples Nacional sem permissão de crédito',
    cod_icms_103: 'Isenção do ICMS no Simples Nacional para faixa de receita bruta',
    cod_icms_201: 'Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária',
    cod_icms_202: 'Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária',
    cod_icms_203: 'Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária',
    cod_icms_300: 'Imune',
    cod_icms_400: 'Não tributada pelo Simples Nacional',
    cod_icms_500: 'ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação',
    cod_icms_900: 'Outros',
    cod_pis_01: 'Operação Tributável (base de cálculo = valor da operação alíquota normal (cumulativo/não cumulativo))',
    cod_pis_02: 'Operação Tributável (base de cálculo = valor da operação (alíquota diferenciada))',
    cod_pis_03: 'Operação Tributável (base de cálculo = quantidade vendida x alíquota por unidade de produto)',
    cod_pis_04: 'Operação Tributável (tributação monofásica (alíquota zero))',
    cod_pis_05: 'Operação Tributável (Substituição Tributária)',
    cod_pis_06: 'Operação Tributável (alíquota zero)',
    cod_pis_07: 'Operação Isenta da Contribuição',
    cod_pis_08: 'Operação Sem Incidência da Contribuição',
    cod_pis_09: 'Operação com Suspensão da Contribuição',
    cod_pis_49: 'Outras Operações de Saída',
    cod_pis_50: 'Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno',
    cod_pis_51: 'Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno',
    cod_pis_52: 'Operação com Direito a Crédito – Vinculada Exclusivamente a Receita de Exportação',
    cod_pis_53: 'Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno',
    cod_pis_54: 'Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação',
    cod_pis_55: 'Operação com Direito a Crédito - Vinculada a Receitas NãoTributadas no Mercado Interno e de Exportação',
    cod_pis_56: 'Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação',
    cod_pis_60: 'Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno',
    cod_pis_61: 'Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno',
    cod_pis_62: 'Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação',
    cod_pis_63: 'Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno',
    cod_pis_64: 'Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação',
    cod_pis_65: 'Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação',
    cod_pis_66: 'Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação',
    cod_pis_67: 'Crédito Presumido - Outras Operações',
    cod_pis_70: 'Operação de Aquisição sem Direito a Crédito',
    cod_pis_71: 'Operação de Aquisição com Isenção',
    cod_pis_72: 'Operação de Aquisição com Suspensão',
    cod_pis_73: 'Operação de Aquisição a Alíquota Zero',
    cod_pis_74: 'Operação de Aquisição; sem Incidência da Contribuição',
    cod_pis_75: 'Operação de Aquisição por Substituição Tributária',
    cod_pis_98: 'Outras Operações de Entrada',
    cod_pis_99: 'Outras Operações',
    doubts: 'Dúvidas',
    logo: 'Logotipo',
    synthetic: 'Sintético',
    analytical: 'Analítico',
    start_on_day: 'Começar no dia?',
    main: 'Principal',
    other: 'Outro',

    collection: 'Cobrança',
    direct_mail: 'Ativar mala direta?',
    direct_mail_sms: 'Ativar mala direta por SMS?',
    direct_mail_messenger: 'Mala direta por Messenger?',
    add_mail: 'Adicionar e-mail',
    add_phone: 'Adicionar Telefone',
    add_address: 'Adicionar Endereço',
    comercial: 'Comercial',
    residence: 'Residêncial',
    mobile: 'Móvel',
    messenger_type: 'Tipo Messenger',
    phones: 'Telefones',
    emails: 'E-mails',
    addresses: 'Endereços'

  },

  auth: {
    'failed': 'Nos desculpe, mas, as informações de login não foram encontradas.',
    'user_disabled': 'Infelizmente a conta informada está desabilidata, verifique com os administradores de sua Empresa.',
    'company_disabled': 'A Empresa da conta informada está desabilitada, para mais informações entre em contato pelo telefone: (12) 3152-9360 ou E-mail: suporte@mbd.com.br',
    'company_selected_disabled': 'A Empresa selecionada está desabilitada, para mais informações entre em contato pelo telefone: (12) 3152-9360 ou E-mail: suporte@mbd.com.br',
    'throttle': 'Poxa... Você tentou logar-se muitas vezes. Por favor tente novamente em :seconds segundos.',
    'not_enter': 'A administração requer que todos os usuários façam login.',
    'choice_company': 'A administração requer que todos os usuários selecionem uma Empresa antes de entrar.',
    'password_wrong': 'Senha antiga não confere. Por favor, você pode verificar isso?',
    'password_update_success': 'Senha alterada com sucesso.'
  },
  message: {
    choice_company_text: 'escolha abaixo qual empresa deseja logar:',
    '403_message': 'No momento você não possui autorização para a página que estava procurando.',
    '401_message_2': 'Caso seja necessário o acesso, entre em contato com responsável de sua empresa.',
    '404_message': 'Não conseguimos encontrar a página que estava procurando. Enquanto isso, você pode',
    '404_message_2': ' retornar ao painel de controle',
    '403_error': 'No momento você não possui autorização para o recurso que estava procurado. Caso necessário, entre em contato com o responsável de sua empresa e solicite a permissão para :permission',    inutiliza_numero: 'Inutilizar numeração',
    error: 'Houve um erro na sua solicitação',
    toggle_navigation: 'Trocar navegação',
    login_message: 'Entre para iniciar uma nova sessão',
    login_success: 'Login efetuado com sucesso.',
    login_error: 'Erro ao efetuar o login.',
    unauthorized: 'Oops, no momento você não tem permissão para fazer isso',
    confirm_delete: 'Tem certeza que deseja deletar o cadastro',
    confirm_delete_certificate: 'Deletar o certificado A1',
    confirm_delete_nfe_item: 'Tem certeza que deseja deletar o item <strong> :item </strong> da Nota Fiscal ?',
    confirm_cancel_payment: 'Tem certeza que deseja cancelar o pagamento',
    confirm_cancel_nfe: 'Tem certeza que deseja cancelar a Nota Fiscal: <br><small style="word-wrap: break-word;"><strong>{chave}</strong></small> ? <br> <strong>OBS: </strong><small>Operação <strong>irreversível</strong> !</small> ',
    confirm_cancel_nfce: 'Tem certeza que deseja cancelar o Cupom Fiscal: <br><small style="word-wrap: break-word;"><strong>{chave}</strong></small> ? <br> <strong>OBS: </strong><small>Operação <strong>irreversível</strong> !</small> ',
    confirm_cancel_pedido: 'Tem certeza que deseja cancelar o Pedido: <br><small style="word-wrap: break-word;"><strong>{chave}</strong></small> ? <br> <strong>OBS: </strong><small>Operação <strong>irreversível</strong> !</small> ',
    confirm_send_nfe: 'Tem certeza que deseja transmitir a Nota Fiscal: <br><small style="word-wrap: break-word;"><strong>{chave}</strong></small> ?',
    confirm_send_nfce: 'Tem certeza que deseja transmitir o Cupom Fiscal: <br><small style="word-wrap: break-word;"><strong>{chave}</strong></small> ?',
    confirm_delete_alert: 'Todos os dados neste registro, incluindo dados acumulados, serão excluídos permanentemente.',
    confirm_delete_alert_a1: 'Sem o certificado, não será possível efetuar operações fiscais. Tem certeza?',
    export_data: 'Todos os dados neste registro, incluindo dados acumulados, serão exportados para um arquivo no formato ".xlsx". Os dados gravados no banco de dados não serão alterados.',
    import_data: 'Para importar dados, selecione um arquivo no formato excel. Clique em "Campos" para verificar os dados que serão importados.',
    create_success: 'Cadastrado com sucesso!',
    add_success: 'Item adicionado com sucesso!',
    add_error: 'Oops, o item <strong> :attribute </strong> já foi adicionado!',
    upload_success: 'Arquivo enviado com sucesso!',
    upload_error: 'Ops, o arquivo enviado inválido.',
    create_company: 'Cadastrar nova Empresa',
    create_error: 'Erro ao cadastrar',
    create_error_user: 'Erro ao cadastrar usuário',
    update_success: 'Atualizado com sucesso!',
    create_user: 'Usuário criado com sucesso!',
    delete_success: 'Deletado com sucesso !',
    delete_success_2: ':object: <br><small><b>:name</b></small> <br>Deletada com sucesso !',
    restore_success_2: ':object: <br><small><b>:name</b></small> <br>Restaurada com sucesso !',
    delete_error: 'Erro ao deletar o :object selecionado.',
    delete_error_2: 'Erro ao deletar a :object selecionada.',
    drag_file: 'Arraste e solte uma imagem aqui.',
    or_select_file: 'Ou selecione uma imagem:',
    invalid_format: 'Erro ao importar os dados. O arquivo informado é inválido. Por favor, informe um arquivo csv/xls válido. Formato enviado: ',
    file_not_found: 'Por favor, informe um arquivo csv/xls válido.',
    import_error: 'Erro ao importar os dados. Por favor, verifique os campos.',
    import_error_fields: 'Erro ao selecionar os campos, por favor, verifique o arquivo enviado. <b>OBS: O arquivo excel tem que conter apenas um plano. </b>',
    import_validation_fail: 'O :model: :object não passou na validação. Por favor, verifique.',
    import_in_row: 'na linha :row',
    import_success: 'Seu arquivo de ":object" foi importado.',
    import_queue: 'Parabéns, seu arquivo foi enviado com sucesso! Estamos trabalhando para importar seus dados, assim que terminar ou caso tenha alguma divergência, nós te informaremos.',
    update_error: 'Não atualizado, verifique os campos.',
    update_error_permission: 'Ops, percebemos que no momento você não tem acesso a permissao <b>:permission</b>, portanto você não pode atribui-la a ninguém. Caso necessário, contate o responsável pela sua empresa.',
    update_error_user: 'Não atualizado, erro ao cadastrar usuário.',
    choice_company_text: 'escolha abaixo qual empresa deseja logar:',
    choice_company: 'Escolha a Empresa',
    session_close: 'Sessão encerrada.',
    user_session_closed: 'Sua sessão foi encerrada com successo.',
    session_close_error: 'Erro ao encerrar a sessão.',
    choice_company_error: 'Erro ao selecionar Empresa.',
    type_password: 'Por favor, preencha a nova senha:',
    min_password: 'Senha tem que ter 6 dígitos',
    zip_not_found: 'Ops, o CEP informado não foi encontrado.',
    update_bank_error: 'Ops, o Banco informado não foi encontrado na base de dados.',
    cond_not_found: 'Ops, a Condição de Pagamento não foi encontrado na base de dados.',
    data_not_found: 'Ops, não encontramos nenhum :object com as informações informadas em nossa base de dados.',
    data_not_found_2: 'Ops, não encontramos nenhuma :object com as informações informadas em nossa base de dados.',
    wrong_sup_wallet: 'Ops, o <b>Tipo de Carteira</b> escolhida não é compatível com <b>Categoria Superior</b>.',
    some_sup_wallet: 'Ops, o <b>Categoria Superior</b> não pode ser o mesmo!',
    wrong_value: 'Ops, o <b>Valor</b> não pode ser o maior que o <b>Valor da Fatura</b>.',
    wrong_repeat_value: 'Ops, o <b>Número de Repetições</b> é inválido. Por favor, você pode verificar isso?',
    wrong_value_discont: 'Ops, o <b>Desconto</b> não pode ser o maior que o <b>Valor da Fatura</b>.',
    wrong_value_interest: 'Ops, o <b>Juros</b> não pode ser o maior que o <b>Valor da Fatura</b>.',
    zero_value: 'Ops, o <b>Valor</b> não pode ser 0',
    wrong_financial_account: 'Ops, <b>Conta</b> escolhida é inválida',
    wrong_bank_account: 'Ops, a <b>Conta Bancária</b> escolhida é inválida',
    invalid_period: 'Ops, a <b>Peridiocidade</b> escolhida é inválida',
    compensate_success: 'compensada com sucesso !',
    payment_success: 'paga com sucesso !  <br><br>',
    plots_were_deleted: 'Conta: <b>:object</b> e suas <b>parcelas</b> foram deletadas com sucesso.',
    received_success: 'recebida com sucesso ! <br><br>',
    payment_error: 'Erro ao efetuar o Pagamento',
    delete_exits_quota: 'A conta que você está deletando possui pagamentos efetuados. Cancele os pagamentos antes.',
    payment_cancel_success: ' estornada com sucesso !',
    payment_cancel_all_success: 'Estornado todos os pagamento da conta: ',
    payment_cancel_error: 'Erro ao cancelar pagamento.',
    wrong_cond_payment: 'Condição de Pagamento inválida.',
    date_print: 'Recibo de Pagamento emitido dia ',
    get_error: 'Ops, ocorreu um erro ao buscar os dados. ',
    wrong_account: 'A conta a ser compensada é inválida. ',
    can_not_delete: 'Você não pode deletar esse registro.',
    can_not_update: 'Você não pode editar esse registro.',
    server_connection: 'Erro ao tentar se conectar com o servidor. Verifique sua conexão com a internet e atualize a pagina.',
    404: 'Conteúdo não encontrado, verifique mais tarde.',
    401: 'Sua sessão foi encerrada, entre novamente para continuar..',
    '401_error': 'Oops! Acesso restrito.',
    '500_error': 'Oops! Aconteceu um erro.',
    '404_error': 'Oops! Pagina não encontrada.',
    '419': 'Ops, houve um erro ao conectar com o servidor. Por favor, atualize a pagina.',
    '401_message': 'No momento você não possui autorização para a página que estava procurando.',
    deleted_all_product_warehouse: 'Deletado todos os produtos <b>:prod</b> do almoxarifado <b>:almox</b>.',
    delete_has_children: 'Existem <b>:children</b> com esse <b>:object</b> selecionado, delete os <b>:children</b> antes.',
    delete_has_children_2: 'Existem <b>:children</b> com essa <b>:object</b> selecionada, delete os <b>:children</b> antes.',
    delete_has_children_3: 'Existem <b>:children</b> com esse <b>:object</b> selecionado, delete as <b>:children</b> antes.',
    delete_has_children_4: 'Existem <b>:children</b> com essa <b>:object</b> selecionada, delete as <b>:children</b> antes.',
    delete_has_children_5: 'Existem <b>:children</b> com esse <b>:object</b> selecionado, mude o <b>:object</b> dos <b>:children</b> antes.',
    deleted_lot_success: 'Lote: <b>:name</b> deletado com sucesso !',
    interval_between_plots: 'Intervalo entre as Parcela',
    person_was_account: 'Ops, o cadastro que você está editando possui uma conta no sistema, por favor, informe um E-mail válido.',
    select_new_maturity: 'Como a fatura não está sendo baixada totalmente, por favor, informe uma nova data de vencimento.',
    confirm_print: 'Sua conta foi baixada com sucesso, você gostaria de emitir um recibo?',
    account_already_paid: 'Ops, a <b>Conta</b> que você selecionou já foi baixada.',
    account_not_payed: 'Ops, a <b>Conta</b> que você selecionou foi cancelada, efetue a baixa para gerar um recibo.',
    payment_already_canceled: 'Ops, a <b>Conta</b> que você selecionou já foi cancelada.',
    click_change_photo: 'Clique aqui para alterar a foto',
    obs_drag_photo: 'OBS: Caso queira remover a foto do perfil, basta clicar em salvar.',
    data_empty: 'Dados não encontrados',
    data_empty_2: 'Poxa, não encontramos nada por aqui...',
    data_empty_3: 'Você pode começar clicando em "Novo".',
    invalid_amount_product: 'Ops, a quantidade informada é inválida. O produto que você selecionou (<b>:name</b> ) não é fracionado. Por favor, informe um número inteiro.',
    invalid_lot_amount: 'Ops, a quantidade informada é inválida. O lote que você selecionou atualmente tem um saldo de: <b>:amount</b>. Por favor, informe um valor válido.',
    no_movements: 'Não há movimentações no período selecionado!',
    no_zero_product: 'Não há produtos com saldo zerado!',
    no_lower_product: 'Não há produtos com saldo abaixo do mínimo!',
    products_zero_balance: 'Você possui :number produtos com saldo zerado',
    products_lower_balance: 'Você possui :number produtos com saldo abaixo do mínimo',
    permission_needed: 'Você precisa da permissão para ',
    user_permission: 'Abaixo, as funçoes que o usuário pode realizar: ',
    post_permission: 'Abaixo, as funçoes que o cargo <strong>{name}</strong> pode realizar: ',
    company_settings: 'Abaixo, as configurações da empresa: :name',
    allow_negative_stock: 'Permitir saldo de estoque negativo ?',
    data_invalid: 'Ops, o :attribute selecionado é inválido. Por favor, verifique.',
    ask_logout: ' Tem certeza que deseja sair do sistema?',
    zero_itens: 'Nenhum item adicionado.',
    no_data: 'Nenhum registro encontrado.',
    see_all: 'Ver todas',
    user_notifications: 'Você não tem notificações | Você tem 1 notificação | Você tem: {amount} notificações',
    notifications_deleted: 'Todas notificações deletadas!',
    notification_deleted: 'Notificação deletada!',
    can_not_update_post: 'Ops, você não pode atribuir um cargo marcado como (Interno) para um usuário que não seja "Administrador".',
    table_empty: 'Não encontramos nenhum :object em nossa base de dados.',
    table_empty_2: 'Não encontramos nenhuma :object em nossa base de dados.',
    email_problem: 'Se você está tendo problemas para clicar no',
    email_problem_2: 'copie e cole o URL abaixo no seu navegador Web:',
    email_reset: 'Você está recebendo este e-mail porque recebemos uma solicitação de redefinição de senha para sua conta.',
    email_reset_2: 'Se você não solicitou uma redefinição de senha, nenhuma outra ação será necessária. Entre em contato conosco se você não enviou esta solicitação.',
    '404_search': 'object: não encontrado.',
    nfe_zero_itens: 'Ops, sua Nota Fiscal não possui itens, por favor adicione antes de continuar.',
    certificate_404: 'Para cadastrar acesse: Configurações/Configurações da Empresa e cadastre seu Certificado A1.Caso não tenha permissão, entre em contato com o responsável da Empresa.',
    certificate_error: 'O certificado não pode ser lido! Verifique a senha.',
    certificate_success: 'Certificado A1 importado com sucesso!',
    certificate_delete_success: 'Certificado A1 deletado com sucesso!',
    nfe_cancel_success: 'Nota Fiscal cancelada com sucesso!',
    nfe_cancel_error: 'Falha ao cancelar a Nota Fiscal.',
    nfe_send_success: 'Nota Fiscal enviada com sucesso!',
    nfe_send_error: 'Falha ao enviar Nota Fiscal.',
    deadline_closed: 'Prazo encerrado.',
    create_nfe_error: 'Houve um erro ao cadastrar um dos itens da NF-e. Verifique o cadastro do produto. Código do erro: :exception ',
    nfe_cancel_dt_invalid: 'Data para cancelamento excedida. Gostaria de proceder? <br> <Strong>OBS: </Strong><small>Procedimento passível de <b>multa</b> por parte da Receita Federal.</small>',
    reason_nfe_cancel: 'Informe o motivo do cancelamento',
    certificate_not_installed: 'Atenção! Seu certificado ainda não foi cadastrado.',
    want_to_buy: 'Quero adquirir',
    register_certificate: 'Cadastrar certificado',
    certificate_expired: 'Atenção! Seu certificado está vencido.',
    certificate_expired_msg: 'Com o Certificado A1 vencido você não poderá realizar operações fiscais.',
    remove_certificate: 'Remover certificado',
    certificate_ok: 'Seu certificado está cadastrado corretamente.',
    certificate_almost_expired: 'Atenção: Menos de um mês para o certificado expirar.',
    certificate_file: 'Arquivo do certificado digital (.pfx):',
    certificate_password: 'Senha do certificado',
    still_with_doubts: 'Ainda com dúvidas?',
    contact_us: 'Então entre em contato com nossa equipe de atendimento. Teremos muito prazer em atendê-lo.',
    help_1: 'Se preferir, acesse nosso',
    help_2: 'atendimento online',
    logo_reports: 'Logotipo para relatórios',
    logo_company: 'Logotipo da Empresa',
    logo_extension: 'Enviar imagem no formato .jpeg',
    remove_logo: 'OBS: Caso queira remover a foto, basta deixar o campo vazio e clicar em salvar.',
    icms_rate: 'Alíquota ICMS do Simples Nacional',
    restore_success: 'Restaurado com sucesso!',

    // New
    min_filter: 'O Filtro deve ser maior que 3.',
    invalid_form: 'Forumário Inválido!',
    invalid_form_text: 'Um ou mais campos não estão válidos. Por favor, <b>verifique</b>',
    confirm_store_company: 'Tem certeza que deseja cadastrar a Empresa?',
    confirm_edit_company: 'Tem certeza que deseja atualizar os dados da Empresa?',

    cnpj_invalid: 'CNPJ Inválido',
    cnpj_invalid_text: 'Ops, o CNPJ informado não é válido.',
    cnpj_valid: 'CNPJ válido',
    cnpj_valid_text: 'Não encontramos problemas com o CNPJ informado',
    cnpj_used_text: 'Ops, o CNPJ informado já foi cadastrado.',
    cnpj_used: 'CNPJ Indisponível',
    cnpj_404_text: 'Ops, o CNPJ informado não foi encontrado para o preenchimento automático.',
    cnpj_404: 'CNPJ não encontrado',
    mail_added: 'E-mail adicionado!',
    phone_added: 'Telefone adicionado!',
    address_added: 'Endereço adicionado!',
    server_reconect: 'Aguarde, estamos reconectando com o servidor..',
    confirm_del_notifications: 'Tem certeza que deseja excluir todas as notificações?',
    document_valid_text: 'Não encontramos problemas com o documento informado',
    document_used_text: 'Ops, o documento informado já foi cadastrado.',
    document_invalid_text: 'Ops, o documento informado não é válido.',
    email_invalid_text: 'O e-mail informado já está em uso. Por favor, escolha outro.',
    email_invalid: 'E-mail inválido.',
    email_valid: 'E-mail válido.',
    email_valid_text: 'Não encontramos problemas com o e-mail informado',
    remember: 'Manter conectado',
    session_closed: 'Sessão encerrada.',
    page_not_found: 'Pagina não encontrada..',
    page_not_found_title: 'Poxa.. não encontramos o endereço que você estava procurando.',
    page_not_found_tip: 'Verifique se o URL inserido está correto. Clique no botão abaixo para retornar à página inicial ou envie um relatório de erros.',
    logout_success: 'Sua sessão foi encerrada com successo.',
    non_charged_customers: 'O seguinte cliente não foi cobrado. | Os seguintes clientes não foram cobrados.'
  }
};
