import axios from 'axios';

const state = {
    data: [],
    select: [],
    orderables: [{
            title: 'Nome',
            name: 'name'
        },
        {
            title: 'Cadastrado em',
            name: 'created_at'
        }
    ],
    filterGroups: [{
        name: 'Voucher',
        filters: [{
                title: 'Nome',
                name: 'nome',
                type: 'string'
            },
            {
                title: 'Cadastrado em',
                name: 'created_at',
                type: 'datetime'
            }
        ]
    }]
};

const mutations = {
    SET_VOUCHER: (state, payload) => {
        state.data = payload;
    },
    SET_VOUCHER_SELECT: (state, payload) => {
        state.select = payload;
    },
};

const actions = {
    setVouchers: (context, payload) => {
        context.commit('SET_VOUCHER', payload);
    },
    setVouchersDeleted: (context, payload) => {
        context.commit('SET_VOUCHER_DELETED', payload);
    },
    storeVoucher: (context, payload) => {
        return axios.post('/api/voucher/cadastrar', payload)
            .then(response => {
                return response.data;
            }).catch(error => {
                return error;
            });
    },
    updateVoucher: (context, payload) => {
        return axios.put(`/api/voucher/${payload.id}/editar`, payload).then(response => {
            return response.data;
        }).catch(error => {
            return error;
        });
    },
    getVoucherById: (context, payload) => {
        return axios.get(`/api/voucher/${payload}/detalhes`).then(response => {
            return response.data;
        }).catch(error => {
            return error;
        });
    },
    consultaVoucher: (context, payload) => {
        let promise = new Promise((resolve, reject) => {
            axios.get(`/api/voucher/${payload}`).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.response.data);
            });
        });
        return promise;
    },
    validaVoucher: (context, payload) => {
        let promise = new Promise((resolve, reject) => {
            axios.post(`/api/voucher/validar`, payload).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.response.data);
            });
        });
        return promise;
    },
};
export default {
    state,
    mutations,
    actions
};