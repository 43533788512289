/* jshint ignore:start */
const routes = [{
    path: '/login',
    component: () => import('./views/auth'),
    name: 'login',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/logar',
    component: () => import('./views/auth/redirect'),
    name: 'logar',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/sign-up',
    component: () => import('./views/auth/sign-up'),
    name: 'signup',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/confirmar-email/:tokenId',
    component: () => import('./views/auth/index'),
    name: 'confirmEmail',
    meta: {
      requiresAuth: false,
      ignoreAuth: true,
    },
  },
  {
    path: '/usuario/registrar/:tokenId',
    component: () => import('./views/auth/index'),
    name: 'confirmarCadastroVinculo',
    meta: {
      //requiresAuth: false,
      ignoreAuth: true,
    },
  },
  {
    path: '/usuario/cadastrar/:tokenId',
    component: () => import('./views/auth/index'),
    name: 'confirmarCadastro',
    meta: {
      //requiresAuth: false,
      ignoreAuth: true,
    },
  },
  {
    path: '/',
    component: () => import('./views/public'),
    name: 'loginIndex',
    meta: {
      requiresAuth: false
    }
  },
  {
    // not found handler
    path: '*',
    component: () =>
      import('./views/auth'),
    name: '404_public',
    meta: {
      title: '404',
      noCache: false,
      autoClose: true
    }
  },
  {
    path: '/password/recovery',
    component: () => import('./views/auth/recovery-password'),
    name: 'recoveryPassword',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/api/password/reset/:token',
    component: () => import('./views/auth/reset-password'),
    name: 'resetPassword',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/cadastrar-usuario/:token',
    component: () => import('./views/auth'),
    name: 'storeUser',
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/home',
    component: () => import('./views/MasterPage'),
    meta: {
      requiresAuth: true
    },
    children: [{
        path: '/home',
        component: () =>
          import('./views/dashboard/index.vue'),
        name: 'home',
        meta: {
          title: 'home',
          noCache: true,
          autoClose: false,
          onlyUser: true
        }
      },
      {
        path: '/dashboard',
        component: () =>
          import('./views/dashboard/dashboard'),
        name: 'dashboard',
        meta: {
          title: 'dashboard',
          noCache: true,
          autoClose: false,
          onlyUser: true
        }
      },
      {
        path: '/profile',
        component: () =>
          import('./views/users/profile.vue'),
        name: 'profile',
        meta: {
          title: 'profile',
          noCache: true
        }
      },
        // settings admin
        {
          path: '/admin-settings',
          component: () =>
            import('./views/users/admin-settings.vue'),
          name: 'adminSettings',
          meta: {
            title: 'adminSettings',
            noCache: true,
            admin: true
          }
        },
      // planos
      {
        path: '/planos',
        component: () =>
          import('./views/plans/index.vue'),
        name: 'planos',
        meta: {
          title: 'planos',
          noCache: true,
          admin: true
        }
      },
      {
        path: '/plano/:id/editar',
        component: () =>
          import('./views/plans/formulario.vue'),
        name: 'planoEdit',
        meta: {
          title: 'planoEdit',
          noCache: true,
          autoClose: true,
          admin: true
        }
      },
      {
        path: '/plano/cadastrar',
        component: () =>
          import('./views/plans/formulario.vue'),
        name: 'planoCreate',
        meta: {
          title: 'planoCreate',
          noCache: true,
          autoClose: true,
          admin: true
        }
      },
      {
        path: '/planos/excluidos',
        component: () =>
          import('./views/plans/deleted.vue'),
        name: 'planoDeleted',
        meta: {
          title: 'planoDeleted',
          noCache: true,
          admin: true
        }
      },
      // Usuários
      {
        path: '/usuarios',
        component: () =>
          import('./views/users/index.vue'),
        name: 'usuarios',
        meta: {
          title: 'users',
          noCache: false,
          admin: true
        }
      },
      {
        path: '/users/:id/editar',
        component: () =>
          import('./views/users/formulario.vue'),
        name: 'userEdit',
        meta: {
          title: 'userEdit',
          noCache: false,
          autoClose: true
        }
      },
      {
        path: '/usuario/:id/permissoes',
        component: () =>
          import('./views/users/permissions.vue'),
        name: 'userPermissions',
        meta: {
          title: 'userPermissions',
          noCache: false,
          autoClose: true
        }
      },
      {
        path: '/users/cadastrar',
        component: () =>
          import('./views/users/formulario.vue'),
        name: 'userCreate',
        meta: {
          title: 'userCreate',
          noCache: false,
          autoClose: true,
          admin: true
        }
      },
      {
        path: '/settings',
        component: () =>
          import('./views/users/settings.vue'),
        name: 'userSettings',
        meta: {
          title: 'userSettings',
          noCache: false,
          autoClose: true
        }
      },
      {
        // not found handler
        path: '*',
        component: () =>
          import('./views/errors/404.vue'),
        name: '404',
        meta: {
          title: '404',
          noCache: false,
          autoClose: true
        }
      },
      {
        // not found handler
        path: '/404',
        component: () =>
          import('./views/errors/404.vue'),
        name: 'notFound',
        meta: {
          title: '404',
          noCache: false,
          autoClose: true
        }
      },
      {
        // not found handler
        path: '/403',
        component: () =>
          import('./views/errors/403.vue'),
        name: '403',
        meta: {
          title: '403',
          noCache: false,
          autoClose: true
        },
        props: true
      },
      {
        path: '/451',
        component: () =>
          import('./views/errors/451.vue'),
        name: '451',
        meta: {
          title: '451',
          noCache: false,
          autoClose: true
        },
        props: true
      },
      {
        path: '/401',
        component: () =>
          import('./views/errors/401.vue'),
        name: '401',
        meta: {
          title: '401',
          noCache: false,
          autoClose: true
        }
      },
    ]
  }
];
export default routes;