/* eslint-disable no-undef */
import Vue from 'vue';
import router from './router';
import store from '../store';

// check if the user is authenticated
/* jshint ignore:start */
router.beforeEach(async (to, from, next) => {
  try {
    let isAuthenticated = await Vue.prototype.$auth.check();
    //console.log(from);
    userOffline(to, isAuthenticated);
    userAdmin(to, from);
    userOnly(to, from);
  } catch (e) {
    console.log(e);
    next(e);
  }
  next();
});

function userOffline(to, isAuthenticated) {
    //user desconectado e rota protegida
    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
      throw routeException('login', {error: trans('auth.not_enter')});
    }

    // Usuário logado mas rota publica
    if (!to.matched.some(record => record.meta.requiresAuth) && isAuthenticated) {
      if (!to.matched.some(r => r.meta.ignoreAuth)) {
        throw routeException('dashboard');
    }
  }
}

function userAdmin(to, from) {
  if (from.meta.admin || to.meta.admin) {
    if (store.getters.user.data.ambient != 'A') {
      throw routeException('403');
    }
  }
}

function userOnly(to, from) {
  if (from.meta.userOnly || to.meta.userOnly) {
    if (store.getters.user.data.ambient == 'S') {
        throw routeException('inbox');
    }
  }
}

function routeException(name, params = null) {
  return {
    name: name,
    params: params
  }
}

// this check will be ran when page is loaded
router.onReady(async (route) => {
  let auth = route.matched.some(record => {
    return record.meta.requiresAuth || false;
  });
  let isAuthenticated = await Vue.prototype.$auth.check();

  if (auth && !isAuthenticated && route.name !== 'login') {
    router.push({
      name: 'login'
    });
  } else if (auth && isAuthenticated && route.name === 'login') {
    router.push({
      name: 'home'
    });
  }
});
/* jshint ignore:end */