import Vue from 'vue';
import VueI18n from 'vue-i18n';
//import Cookies from 'js-cookie';
//import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
//import elementPtbrLocale from 'element-ui/lib/locale/lang/pt-br'// element-ui lang
import enLocale from './en';
import ptbrLocale from './pt_BR';
import VeeValidate from 'vee-validate';
//import ptBr from 'element-ui/lib/locale/lang/pt-br';
import common from '../functions/common'
Vue.use(VueI18n);

VeeValidate.Validator.extend('document', {
  getMessage: field => `The ${field} value is not a document.`,
  validate: (value) => {
    if (value.length < 15) {
      return common.methods.isValidCpf(value);
    } else {
      return common.methods.isValidCnpj(value);
    }
  }
});

VeeValidate.Validator.extend('onlyDigits', {
  validate: (value, args) => {
    return (value.replaceAll(' ', '').length == args[0])
  }
});

Vue.use(VeeValidate, {
  locale: 'pt_BR',
  fieldsBagName: 'vvFields',
  events: '',
  dictionary: {
    'en': {
      messages: enLocale.validation,
      attributes: enLocale.attributes
    },
    'pt_BR': {
      messages: ptbrLocale.validation,
      attributes: ptbrLocale.attributes
    }
  }
});

/* jshint ignore:start */
const messages = {
  en: {
    ...enLocale,
    //   ...elementEnLocale
  },
  'pt_BR': {
    ...ptbrLocale,
    //   ...elementPtbrLocale
  },
};
/* jshint ignore:end */
let vuex = localStorage.getItem('vuex');
vuex = JSON.parse(vuex);
vuex =  'pt_BR';// vuex === null ? 'pt_BR' : vuex.user.localSettings.user_language;

const i18n = new VueI18n({
  locale: vuex, // set locale 
  //locale: Cookies.get('language') || 'pt_BR', // set locale 
  messages // set locale messages
});

export default i18n;