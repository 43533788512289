/* eslint-disable no-undef */
export const getInitClient = (context) => {
  return api.get('init/client')
    .then((response) => {
      context.commit('SET_CLIENT', response.data.result);
    })
    .catch((error) => {
      context.dispatch('errorModal', { title: 'Init Client Failed', message: error.body.errormessage });
    });
};
