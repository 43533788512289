/* export const value = (param) => {
 console.log('Getter', param);
 };

 export const user = state => {
 return state.user;
 }
 */
const getters = {
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  user: state => state.user,
  users: state => state.user.select,
  revendas: state => state.empresa.revenda
};
export default getters;
