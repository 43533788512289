import axios from 'axios';

const state = {
  data: [],
  orderables: [{
      title: 'Descrição',
      name: 'termo_descricao'
    },
    {
      title: 'Cadastrado em',
      name: 'created_at'
    }
  ],
  filterGroups: [{
    name: 'Termo',
    filters: [
      {
        title: 'Descrição',
        name: 'termo_descricao',
        type: 'string'
      },
      {
        title: 'Cadastrado em',
        name: 'created_at',
        type: 'datetime'
      }
    ]
  }]
};

const mutations = {
  SET_TERMOS: (state, payload) => {
    state.data = payload;
  }
};

const actions = {
  setTermos: (context, payload) => {
    context.commit('SET_TERMOS', payload);
  },
  storeTermo: (context, payload) => {
    return axios.post('/api/termo/cadastrar', payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        return error.response.data;
      });
  },
  updateTermo: (context, payload) => {
    return axios.put(`/api/termo/${payload.id}/editar`, payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  aprovarTermo: (context, payload) => {
    return axios.patch(`/api/termo/${payload}/aprovar`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  aceitarTermos: (context, payload) => {
    return axios.put('/api/termos/aceitar', payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  getTermoById: (context, payload) => {
    return axios.get(`/api/termo/${payload}/detalhes`).then(response => {
      return response.data;
    }).catch(error => {
      return error;
    });
  },
};
export default {
  state,
  mutations,
  actions
};