import axios from 'axios';

const state = {
    data: [],
    select: [],
    orderables: [{
            title: 'Nome',
            name: 'name'
        },
        {
            title: 'Cadastrado em',
            name: 'created_at'
        }
    ],
    filterGroups: [{
        name: 'Participante',
        filters: [{
                title: 'Nome',
                name: 'name',
                type: 'string'
            },
            {
                title: 'Cadastrado em',
                name: 'created_at',
                type: 'datetime'
            }
        ]
    }]
};

const mutations = {
    SET_PARTICIPANTE: (state, payload) => {
        state.data = payload;
    },
    SET_PARTICIPANTE_SELECT: (state, payload) => {
        state.select = payload;
    },
};

const actions = {
    setParticipantes: (context, payload) => {
        context.commit('SET_PARTICIPANTE', payload);
    },
    setParticipantesDeleted: (context, payload) => {
        context.commit('SET_PARTICIPANTE_DELETED', payload);
    },
    storeParticipante: (context, payload) => {
        return axios.post('/api/participante/cadastrar', payload)
            .then(response => {
                return response.data;
            }).catch(error => {
                return error;
            });
    },
    updateParticipante: (context, payload) => {
        return axios.put(`/api/participante/${payload.id}/editar`, payload).then(response => {
            return response.data;
        }).catch(error => {
            return error;
        });
    },
    getParticipanteById: (context, payload) => {
        return axios.get(`/api/participante/${payload}/detalhes`).then(response => {
            return response.data;
        }).catch(error => {
            return error;
        });
    },
    lancarVenda: (context, payload) => {
        let promise = new Promise((resolve, reject) => {
            axios.post(`/api/pontos/cpf`, payload).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.response.data);
            });
        });
        return promise;
    },
};
export default {
    state,
    mutations,
    actions
};